import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'LAUDO_OCORRENCIA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setFiltroOcorrencia(filtro = {}) {
    return {
        type: 'LAUDO_OCORRENCIA_FILTRO',
        payload: filtro
    };
}

export function initForm(registro = {}) {
    return [
        initialize('laudoOcorrenciaForm', registro)
    ];
}

export function getLista() {
	
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/laudoOcorrencia`, {
			params: {
				ids_controle: getState().laudoOcorrencia.filtro.ids_controle
			}
		})
		.then(resp => {
			dispatch({
		        type: 'LAUDO_OCORRENCIA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaControle() {
	return (dispatch, getState) => {

		axios.get(`${consts.API_URL}/laudoControle`).then(resp => {
			dispatch({
				type: 'LAUDO_CONTROLE_LISTADO',
		        payload: resp
			});
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/laudoOcorrencia`, {
				...registro,
				datahora_abertura: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_abertura),
				datahora_fechamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fechamento),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_controle, registro.id_tipo));
				dispatch(getListaControle());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/laudoOcorrencia`, {
				...registro,
				datahora_abertura: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_abertura),
				datahora_fechamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fechamento),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_controle, registro.id_tipo));
				dispatch(getListaControle());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/laudoOcorrencia?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_controle, registro.id_tipo));
			dispatch(getListaControle());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/laudoOcorrenciaTipo/listarSelect`);
    return {
        type: 'LAUDO_OCORRENCIA_TIPO_SELECT_LISTADO',
        payload: request
    };
}