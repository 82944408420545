import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './ocorrenciaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import SelectDialog from '../common/form/selectDialog';

import { 
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, 
	getListaTipo, getListaMotorista, getListaGravidade, getListaLocal, 
	getListaResponsabilidade, getListaTipoCusto, salvarAbona, 
	getListaStatus, getListaAcao, getListaUsuario, getListaCategoria,
	getListaOperacao, getListaClasse, alterarOcorrenciaVisualizadaResponsavel,
	getListaAnalise
} from './ocorrenciaActions';
import Aguardando from '../common/template/aguardando';
import InputPesquisa from '../common/form/inputPesquisa';

class OcorrenciaDocumentoViagem extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		id_situacao: 1,
		id_interacao: 1,
		modoTela: 'lista'
	}

    componentWillMount() {
        this.props.getLista(true);
		this.props.getListaTipo();
		this.props.getListaMotorista();
		this.props.getListaGravidade();
		this.props.getListaLocal();
		this.props.getListaResponsabilidade();
		this.props.getListaTipoCusto();
		this.props.getListaStatus();
		this.props.getListaAcao();
		this.props.getListaUsuario();
		this.props.getListaCategoria();
		this.props.getListaOperacao();
		this.props.getListaClasse();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastroAvulsa' ? (
						<Form onSubmit={(registro) => this.props.salvar(registro)} />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form resumoDocumento onSubmit={(registro) => this.props.salvar(registro)} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form resumoDocumento excluir onSubmit={(registro) => this.props.excluir(registro)} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = (this.state.modoTela == 'lista' ? this.props.lista : this.props.listaAnalise).filter(item => {

			if (!item.id_documento_viagem 
				&& !item.id_vale_pedagio_entrada && !item.id_vale_pedagio_saida && !item.id_vale_pedagio_transferencia
				&& !item.avulsa) {
				return false;
			}

			if ((this.state.ids_status || []).length && !this.state.ids_status.includes(item.id_status)) {
				return false;
			}
			if ((this.state.ids_categoria || []).length && !this.state.ids_categoria.includes(item.id_categoria)) {
				return false;
			}
			if ((this.state.ids_tipo || []).length && !this.state.ids_tipo.includes(item.id_tipo)) {
				return false;
			}
			if ((this.state.ids_area || []).length && !this.state.ids_area.includes(item.id_area)) {
				return false;
			}
			if ((this.state.ids_motorista || []).length && !this.state.ids_motorista.includes(item.id_motorista)) {
				return false;
			}
			if ((this.state.ids_classe || []).length && !this.state.ids_classe.includes(item.id_classe)) {
				return false;
			}
			if ((this.state.ids_usuario_responsavel || []).length && !this.state.ids_usuario_responsavel.includes(item.id_usuario_responsavel)) {
				return false;
			}
			if ((this.state.ids_operacao || []).length && !this.state.ids_operacao.includes(item.id_operacao)) {
				return false;
			}
			if ((this.state.id_situacao == 2 && item.datahora_concluido) || (this.state.id_situacao == 3 && !item.datahora_concluido)) {
				return false;
			}
			if ((this.state.tipos || []).length && !this.state.tipos.includes(item.tipo)) {
				return false;
			}

			if (!this.state.id_interacao || this.state.id_interacao == 1) { //Todos
				
			} else if (this.state.id_interacao == 2 && item.datahora_interacao) { //Sem Interação
				return false;
			} else if (this.state.id_interacao == 3 && !item.datahora_interacao) { //Com Interação
				return false;
			} else if (this.state.id_interacao == 4 && item.dias_interacao < 5) { //5 dias
				return false;
			} else if (this.state.id_interacao == 5 && item.dias_interacao < 10) { //10 dias
				return false;
			} else if (this.state.id_interacao == 6 && item.dias_interacao < 15) { //15 dias
				return false;
			}


			if ((this.state.pesquisar || '').trim()) {
				if ((this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
					if (pesquisar.trim()) {
						if ((`
							${item.id}
							${item.tipo}
							${item.dias_aberto}
							${item.nome_status}
							${item.id_tipo} - ${item.nome_tipo}
							${DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}
							${DateFormat.formatarDataSqlParaTela(item.datahora_visualizacao_responsavel)}
							${DateFormat.formatarDataSqlParaTela(item.datahora_concluido)}
							${FormatUtils.formatarValorTela(item.quantidade_interacoes, 0)}
							${DateFormat.formatarDataSqlParaTela(item.datahora_interacao)}
							${item.tipo_documento}
							${item.numero_documento}
							${item.nome_acao}
							${item.nome_classe}
							${item.nome_ocorrencia_responsavel}
							${item.nome_responsavel}
							${item.nome_cliente}
							${item.nome_destinatario}
							${DateFormat.formatarDataSqlParaTela(item.datahora_carga)}
							${DateFormat.formatarDataSqlParaTela(item.datahora_entrega)}
							${item.nome_motorista}
							${item.cavalo}
							${item.nome_operacao}
							${item.observacao}
							${item.nome_usuario_interacao}
							${item.ultimo_comentario}
						`).toUpperCase().includes(pesquisar.trim())) {
							return true;
						} else {
							return false;
						}
					}
				}).length == 0) {
					return false;
				}
			}


			if (!this.props.usuarioCarregado) {
				return false;
			} else if (
				this.props.usuarioCarregado.acesso_ocorrencia
				|| this.props.sessao.id == 13 // Mauricio Junior Sfalcin
				|| this.props.sessao.id == 30 // Clarissa Brauner
				|| this.props.sessao.id == 23 // Alesandra Fatima da Silva Scheuer

				|| this.props.sessao.id == 48 // Bruna Schneider Padilha
				|| this.props.sessao.id == 31 // Alana Costa
				|| this.props.sessao.id == 58 // Vanessa Brizzi da Silva

				|| this.props.sessao.id == 23 // Alesandra Fatima da Silva Scheuer
				|| this.props.sessao.id == 67  // Agatha Nila Correa Maciel
				|| this.props.sessao.id == 68 // Arielli Jaqueline Gross Boeno
				|| this.props.sessao.id == 120 // Bruna Grazieli Barbosa

				|| this.props.sessao.id == 37 //Rodrigo Soares

			) {
				return true;
			} else if (
				item.id_usuario_inclusao == this.props.usuarioCarregado.id
				|| item.id_usuario_responsavel == this.props.usuarioCarregado.id
				|| item.id_usuario_aprovador == this.props.usuarioCarregado.id
			) {
				return true;
			}
			return false;
		});

		lista.sort((a, b) => new Date(a.datahora_inclusao).getTime() - new Date(b.datahora_inclusao).getTime());

		return (
			<ContentCard>
				<ContentCardBody>
					<Row>
						<Select
							minWidth={200}
							name='tipo_periodo'
							options={[
								{ id: 'datahora_ocorrencia', valor: 'Data da Ocorrência' }, 
								{ id: 'datahora_inclusao', valor: 'Data de Abertura' },
								{ id: 'datahora_emissao', valor: 'Data de Emissão' }, 
								{ id: 'datahora_entrega', valor: 'Data de Entrega' },
								{ id: 'datahora_interacao', valor: 'Data da Última Interação' }
							]}
							label='Período'
							cols='12 6 2 1'
							placeholder='Selecione o período'
							value={this.props.filtro.tipo_periodo}
							onChange={(value) => {
								this.props.setFiltro({ ...this.props.filtro, tipo_periodo: value });
							}} />

						<LabelAndInputMask
							maxWidth={140}
							label='Data/Hora Início' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							maxWidth={140}
							label='Data/Hora Fim' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
				
						<SelectDialog
							minWidth={200}
							name='tipo'
							options={[{ id: 'DOC', valor: 'Documento' }, { id: 'VPR', valor: 'VPR' }, { id: 'AVULSA', valor: 'Avulsa' }]}
							label='Tipo'
							cols='12 6 2 1'
							placeholder='Selecione o tipo'
							multiple
							value={this.state.tipos}
							onChange={(value) => {
								this.setState({
									...this.state,
									tipos: value
								})
							}} />

						<Select
							minWidth={200}
							name='id_situacao'
							options={[{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Em Aberto' }, { id: 3, valor: 'Finalizado' }]}
							label='Situação'
							cols='12 6 2 1'
							placeholder='Selecione a situação'
							value={this.state.id_situacao}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_situacao: value
								})
							}} />

						<SelectDialog
							minWidth={200}
							name='id_status'
							options={this.props.listaStatus}
							label='Status'
							cols='12 6 2 1'
							placeholder='Selecione o status'
							multiple
							value={this.state.ids_status}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_status: value
								})
							}} />
							
						<SelectDialog
							minWidth={200}
							name='id_categoria'
							options={this.props.listaCategoria}
							label='Categoria'
							cols='12 6 2 1'
							placeholder='Selecione a categoria'
							multiple
							value={this.state.ids_categoria}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_categoria: value
								})
							}} />
							
						<SelectDialog
							minWidth={250}
							name='id_tipo'
							options={this.props.listaTipo.filter(item => {
								
								if (!item.documentacao) {
									return false;
								}

								return true;
							})}
							label='Tipo de Ocorrência'
							cols='12 6 2 1'
							placeholder='Selecione o tipo'
							multiple
							value={this.state.ids_tipo}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_tipo: value
								})
							}} />
							
						<SelectDialog
							minWidth={200}
							name='id_area'
							options={this.props.listaResponsabilidade}
							label='Área Responsável'
							cols='12 6 2 1'
							placeholder='Selecione a área'
							multiple
							value={this.state.ids_area}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_area: value
								})
							}} />

						<SelectDialog
							minWidth={250}
							name='id_classe'
							options={this.props.listaClasse}
							label='Classe'
							cols='12 6 2 1'
							placeholder='Selecione a classe'
							multiple
							value={this.state.ids_classe}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_classe: value
								})
							}} />
							
						<SelectDialog
							minWidth={250}
							name='id_usuario_responsavel'
							options={this.props.listaUsuario.filter(item => !item.acesso_cliente)}
							label='Responsável'
							cols='12 6 2 1'
							placeholder='Selecione o responsável'
							multiple
							value={this.state.ids_usuario_responsavel}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_usuario_responsavel: value
								})
							}} />

						<SelectDialog
							minWidth={250}
							name='id_motorista'
							options={this.props.listaMotorista}
							label='Motorista'
							cols='12 6 2 1'
							placeholder='Selecione o motorista'
							multiple
							value={this.state.ids_motorista}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_motorista: value
								})
							}} />
						
						<SelectDialog
							minWidth={250}
							name='ids_operacao'
							options={this.props.listaOperacao}
							label='Operação'
							cols='12 6 2 1'
							placeholder='Selecione a operação'
							multiple
							value={this.state.ids_operacao}
							onChange={(value) => {
								this.setState({
									...this.state,
									ids_operacao: value
								})
							}} />

						<Select
							minWidth={250}
							name='id_interacao'
							options={[
								{ id: 1, valor: 'Todos' }, { id: 2, valor: 'Sem Interação' }, { id: 3, valor: 'Com Interação' }, 
								{ id: 4, valor: '5 dias' }, { id: 5, valor: '10 dias' }, { id: 6, valor: '15 dias' }
							]}
							label='Interação'
							cols='12 6 2 1'
							placeholder='Selecione a última interação'
							value={this.state.id_interacao}
							onChange={(value) => {
								this.setState({
									...this.state,
									id_interacao: value
								})
							}} />

					</Row>

					<Row>

						<InputPesquisa
							cols='12 6 6 6'
							label='Pesquisar'
							onChange={value => this.setState({ ...this.state, pesquisar: value })} />

						<Grid cols='12 6 6 6' style={{ marginTop: 8 }}>
							<div style={{ width: 320 }}>

								<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}
									onClick={() => {
										this.props.setModoTela('cadastroAvulsa', { 
											datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), 
											quantidade: 1,
											avulsa: true
										});
										this.props.initForm({ 
											datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()), 
											quantidade: 1,
											avulsa: true
										});
									}}>
									<i class='fas fa-plus'></i> Avulsa
								</a>

								<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
									onClick={() => {
										if (this.state.modoTela == 'lista') {
											this.props.getLista(true);
										} else {
											this.props.getListaAnalise();
										}
										this.props.getListaTipo();
										this.props.getListaMotorista();
										this.props.getListaGravidade();
										this.props.getListaLocal();
										this.props.getListaResponsabilidade();
										this.props.getListaTipoCusto();
									}}>
									<i class='fas fa-sync-alt'></i> Atualizar
								</a>

								<ExcelFile
									filename={'Track 2.0'}
									element={
										<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
											<i class='fas fa-file-excel'></i> Exportar
										</a>
									}>
									<ExcelSheet data={lista.map(item => ({
										...item,
										datahora_inclusao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao),
										datahora_visualizacao_responsavel: DateFormat.formatarDataHoraSqlParaTela(item.datahora_visualizacao_responsavel),
										datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
										percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
										datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
										datahora_concluido: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
										datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
										datahora_interacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_interacao),
										datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_emissao),
										datahora_entrega: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrega),
										custo: FormatUtils.formatarValorTela(item.custo, 2),
										valor: FormatUtils.formatarValorTela(item.valor, 2),
										valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2),
										nome_categoria: item.nome_categoria || 'Sem Categoria'
									}))} name='Ocorrências'>
										<ExcelColumn label='Número' value='id' />
										<ExcelColumn label='Tipo' value='tipo' />
										<ExcelColumn label='D/A' value='dias_aberto' />
										<ExcelColumn label='Status' value='nome_status' />
										<ExcelColumn label='ID Tipo de Ocorrência' value='id_tipo' />
										<ExcelColumn label='Tipo de Ocorrência' value='nome_tipo' />
										<ExcelColumn label='Abertura' value='datahora_inclusao' />
										<ExcelColumn label='Início' value='datahora_visualizacao_responsavel' />
										<ExcelColumn label='Finalização' value='datahora_concluido' />
										<ExcelColumn label='QI' value='quantidade_interacoes' />
										<ExcelColumn label='Última Interação' value='datahora_interacao' />
										<ExcelColumn label='Tp.Doc.' value='tipo_documento' />
										<ExcelColumn label='Documento' value='numero_documento' />
										<ExcelColumn label='Ação Requerida' value='nome_acao' />
										<ExcelColumn label='Classe' value='nome_classe' />
										<ExcelColumn label='Área Responsável' value='nome_ocorrencia_responsavel' />
										<ExcelColumn label='Responsável' value='nome_responsavel' />
										<ExcelColumn label='Categoria' value='nome_categoria' />
										<ExcelColumn label='Remetente' value='nome_cliente' />
										<ExcelColumn label='Recebedor' value='nome_destinatario' />
										<ExcelColumn label='Coleta' value='datahora_carga' />
										<ExcelColumn label='Entrega' value='datahora_entrega' />
										<ExcelColumn label='Motorista' value='nome_motorista' />
										<ExcelColumn label='Placa' value='cavalo' />
										<ExcelColumn label='Operação' value='nome_operacao' />
										<ExcelColumn label='Observação' value='observacao' />
										<ExcelColumn label='Usuário' value='nome_usuario_interacao' />
										<ExcelColumn label='Último Comentário' value='ultimo_comentario' />
									</ExcelSheet>
								</ExcelFile>

								<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
									onClick={() => {

										const novo = this.state.modoTela == 'lista' ? 'analise' : 'lista';
										this.setState({
											...this.state,
											modoTela: novo
										});
										if (novo == 'lista') {
											this.props.getLista(true);
										} else {
											this.props.getListaAnalise();
										}
									}}>
									<i class={this.state.modoTela == 'lista' ? 'fas fa-chart-bar' : 'fas fa-table'}></i> 
										{this.state.modoTela == 'lista' ? 'Análise' : 'Lista'}
								</a>

							</div>
						</Grid>
					</Row>
					
					{this.state.modoTela == 'lista' &&
					<Table responsive tableBordered>
						<THead>
							<Tr>
								<Th width={80}></Th>
								<Th width={5} alignCenter title={'Categoria'}>Cat.</Th>
								<Th width={5} alignCenter><i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} /></Th>
								<Th width={5} alignCenter>Número</Th>
								<Th width={5} alignCenter>Tipo</Th>
								<Th width={5} alignCenter>D/A</Th>
								<Th width={50} alignCenter>Status</Th>
								<Th width={150} alignCenter>Tipo de Ocorrência</Th>
								<Th width={50} alignCenter>Abertura</Th>
								<Th width={50} alignCenter title='Usuário responsável visualizou'>Início</Th>
								<Th width={50} alignCenter>Fim</Th>
								<Th width={10} alignCenter title='Quantidade de Interações'>QI</Th>
								<Th width={50} alignCenter title='Última Interação'>Últ. Int.</Th>
								<Th width={10} alignCenter>Doc.</Th>
								<Th width={10} alignCenter>Documento</Th>
								<Th width={200} alignCenter>Ação Requerida</Th>
								<Th width={80} alignCenter>Classe</Th>
								<Th width={60} alignCenter>Área Resp.</Th>
								<Th width={100} alignCenter>Responsável</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<>
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`} >
										<Td alignCenter minWidth={80}>
											<ButtonTable
												type={'primary'}
												icon={this.state.idOcorrenciaExpandida == item.id ? 'fas fa-minus' : 'fas fa-plus'}
												visible={true}
												small
												title={'Ver Detalhes'}
												event={() => {
													this.setState({
														...this.state,
														idOcorrenciaExpandida: this.state.idOcorrenciaExpandida == item.id ? null : item.id
													})		
												}} />

											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												small
												event={() => this.eventoAlterar(item)} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												small
												event={() => this.eventoExcluir(item)} />
										</Td>
										<Td alignCenter title={item.nome_categoria || 'Sem Categoria'}>
											<i class='fas fa-circle' title={item.nome_categoria || 'Sem Categoria'} style={{ fontSize: 18, color: item.cor_categoria || '#bfbfbf' }}></i>
										</Td>
										<Td alignCenter minWidth={5} small noWrap>
											{(item.arquivo || []).length > 0 &&
											<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
										</Td>
										<Td alignCenter minWidth={5} small noWrap>{item.id}</Td>
										<Td alignCenter minWidth={5} small noWrap>{item.tipo}</Td>
										<Td alignCenter minWidth={5} small noWrap>{item.dias_aberto}</Td>
										<Td alignCenter minWidth={50} small noWrap>{item.nome_status}</Td>
										<Td alignCenter minWidth={150} small noWrap>{item.id_tipo} - {item.nome_tipo}</Td>
										<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
										<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_visualizacao_responsavel)}</Td>
										<Td alignCenter minWidth={50} small>{DateFormat.formatarDataSqlParaTela(item.datahora_concluido)}</Td>
										<Td alignCenter minWidth={10} small>{FormatUtils.formatarValorTela(item.quantidade_interacoes, 0)}</Td>
										<Td alignCenter minWidth={50} small
											color={(item.datahora_interacao && item.datahora_interacao == item.datahora_interacao_responsavel) ? (item.datahora_interacao > item.datahora_comentario_visto || !item.datahora_comentario_visto ? '#ff0000' : null) : null}
											>{(item.datahora_interacao && item.datahora_interacao == item.datahora_interacao_responsavel) ? (item.datahora_interacao > item.datahora_comentario_visto || !item.datahora_comentario_visto ? <i class='fas fa-bell'></i> : null) : null} {DateFormat.formatarDataSqlParaTela(item.datahora_interacao)}</Td>
										<Td alignCenter minWidth={10} small noWrap>{item.tipo_documento}</Td>
										<Td alignCenter minWidth={10} small noWrap>{item.numero_documento}</Td>
										<Td alignCenter minWidth={200} small noWrap>{item.nome_acao}</Td>
										<Td alignCenter minWidth={80} small noWrap>{item.nome_classe}</Td>
										<Td alignCenter minWidth={60} small noWrap>{item.nome_ocorrencia_responsavel}</Td>
										<Td alignCenter minWidth={100} small noWrap>{item.nome_responsavel}</Td>
									</Tr>
									{this.state.idOcorrenciaExpandida == item.id &&
									<Tr key={`${item.id}-comp`} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td colspan={19} small>
											<Row style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 8, paddingBottom: 8, backgroundColor: '#dae0f5' }}>
												<Grid cols='12 6 3 3' style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>
													<p style={{ margin: 0 }}><b>Remetente:</b> {item.nome_cliente}</p>
													<p style={{ margin: 0 }}><b>Recebedor:</b> {item.nome_destinatario}</p>
													<p style={{ margin: 0 }}><b>Operação:</b> {item.nome_operacao}</p>
												</Grid>
												<Grid cols='12 6 2 2' style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>
													<p style={{ margin: 0 }}><b>Coleta:</b> {DateFormat.formatarDataSqlParaTela(item.datahora_carga)}</p>
													<p style={{ margin: 0 }}><b>Entrega:</b> {DateFormat.formatarDataSqlParaTela(item.datahora_entrega)}</p>
												</Grid>
												<Grid cols='12 6 3 3' style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>
													<p style={{ margin: 0 }}><b>Motorista:</b> {item.nome_motorista}</p>
													<p style={{ margin: 0 }}><b>Placa:</b> {item.cavalo}</p>
												</Grid>
												<Grid cols='12 6 4 4' style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4 }}>													
													<p style={{ margin: 0 }}><b>Observação:</b> {item.observacao}</p>
													<p style={{ margin: 0 }}><b>Usuário:</b> {item.nome_usuario_interacao}</p>
													<p style={{ margin: 0 }}><b>Últ. Comentário:</b> {item.ultimo_comentario}</p>
												</Grid>
											</Row>
										</Td>
									</Tr>}
								</>
							))}
						</TBody>
					</Table>}

					{this.state.modoTela == 'analise' ?
						this.modoAnalise(lista)
					: null}
				</ContentCardBody>
			</ContentCard>
		);
	}

	modoAnalise(lista) {

		return (
			<Row>
				<Grid cols='12 12 6 6'>
					{this.analiseTempoInicio(lista)}
				</Grid>
				<Grid cols='12 12 6 6'>
					{this.analiseTempoEncerramento(lista)}
				</Grid>
				<Grid cols='12 12 6 6'>
					{this.analiseArea(lista)}
				</Grid>
				<Grid cols='12 12 6 6'>
					{this.analiseCategoria(lista)}
				</Grid>
				<Grid cols='12 12 6 6'>
					{this.analiseTipoOcorrencia(lista)}
				</Grid>
				<Grid cols='12 12 6 6'>
					{this.analiseResponsavel(lista)}
				</Grid>
			</Row>
		)

	}

	analiseTempoInicio(lista) {

		let periodo5 = lista.filter(item => parseInt(item.dias_inicio || 0) < 5).length;
		let periodo5A10 = lista.filter(item => parseInt(item.dias_inicio || 0) >= 5 && parseInt(item.dias_inicio || 0) < 10).length;
		let periodo10A20 = lista.filter(item => parseInt(item.dias_inicio || 0) >= 10 && parseInt(item.dias_inicio || 0) < 20).length;
		let periodo20A30 = lista.filter(item => parseInt(item.dias_inicio || 0) >= 20 && parseInt(item.dias_inicio || 0) < 30).length;
		let periodo30 = lista.filter(item => parseInt(item.dias_inicio || 0) > 30).length;
		let total = lista.length;

		let agrupadores = total == 0 ? [] : [
			{ nome: 'Até 5 dias', quantidade: periodo5, percentual: parseInt((periodo5 * 100) / total) },
			{ nome: 'De 5 a 10 dias', quantidade: periodo5A10, percentual: parseInt((periodo5A10 * 100) / total) },
			{ nome: 'De 10 a 20 dias', quantidade: periodo10A20, percentual: parseInt((periodo10A20 * 100) / total) },
			{ nome: 'De 20 a 30 dias', quantidade: periodo20A30, percentual: parseInt((periodo20A30 * 100) / total) },
			{ nome: 'Mais de 30 dias', quantidade: periodo30, percentual: parseInt((periodo30 * 100) / total) }
		];

		if (total > 0) {
			let percentualTotal = 0;
			let maior = 0;
			let agrupadorMaior = null;
			agrupadores.forEach(item => {
				percentualTotal += item.percentual;
				if (item.percentual > maior) {
					maior = item.percentual;
					agrupadorMaior = item.nome;
				}
			});

			if (percentualTotal < 100) {
				agrupadores = agrupadores.map(item => {
					if (item.nome == agrupadorMaior) {
						return {
							...item,
							percentual: item.percentual + (100 - percentualTotal)
						};
					} else {
						return item;
					}
				});
			}
		}

		return this.componenteAnalise('Início', 'Por Tempo de Início', agrupadores);

	}

	analiseTempoEncerramento(lista) {

		let periodo5 = lista.filter(item => parseInt(item.dias_aberto || 0) < 5).length;
		let periodo5A10 = lista.filter(item => parseInt(item.dias_aberto || 0) >= 5 && parseInt(item.dias_aberto || 0) < 10).length;
		let periodo10A20 = lista.filter(item => parseInt(item.dias_aberto || 0) >= 10 && parseInt(item.dias_aberto || 0) < 20).length;
		let periodo20A30 = lista.filter(item => parseInt(item.dias_aberto || 0) >= 20 && parseInt(item.dias_aberto || 0) < 30).length;
		let periodo30 = lista.filter(item => parseInt(item.dias_aberto || 0) > 30).length;
		let total = lista.length;

		let agrupadores = total == 0 ? [] : [
			{ nome: 'Até 5 dias', quantidade: periodo5, percentual: parseInt((periodo5 * 100) / total) },
			{ nome: 'De 5 a 10 dias', quantidade: periodo5A10, percentual: parseInt((periodo5A10 * 100) / total) },
			{ nome: 'De 10 a 20 dias', quantidade: periodo10A20, percentual: parseInt((periodo10A20 * 100) / total) },
			{ nome: 'De 20 a 30 dias', quantidade: periodo20A30, percentual: parseInt((periodo20A30 * 100) / total) },
			{ nome: 'Mais de 30 dias', quantidade: periodo30, percentual: parseInt((periodo30 * 100) / total) }
		];

		if (total > 0) {
			let percentualTotal = 0;
			let maior = 0;
			let agrupadorMaior = null;
			agrupadores.forEach(item => {
				percentualTotal += item.percentual;
				if (item.percentual > maior) {
					maior = item.percentual;
					agrupadorMaior = item.nome;
				}
			});

			if (percentualTotal < 100) {
				agrupadores = agrupadores.map(item => {
					if (item.nome == agrupadorMaior) {
						return {
							...item,
							percentual: item.percentual + (100 - percentualTotal)
						};
					} else {
						return item;
					}
				});
			}
		}

		return this.componenteAnalise('Finalização', 'Por Tempo de Encerramento', agrupadores);

	}

	analiseArea(lista) {

		let agrupadores = [];
		lista.forEach(item => {
			if (item.nome_ocorrencia_responsavel && !agrupadores.filter(agrupador => agrupador.nome == item.nome_ocorrencia_responsavel).length) {
				agrupadores.push({
					nome: item.nome_ocorrencia_responsavel,
					quantidade: 0,
					percentual: 0
				});
			}
		});
		let total = lista.length;
		let percentualTotal = 0;
		if (total > 0) {
			agrupadores = agrupadores.map(agrupador => {
				let quantidade = lista.filter(item => item.nome_ocorrencia_responsavel == agrupador.nome).length;
				percentualTotal += parseInt((quantidade * 100) / total);

				return {
					...agrupador,
					quantidade: quantidade,
					percentual: parseInt((quantidade * 100) / total)
				}
			});
		}
		

		agrupadores.sort((a, b) => b.quantidade - a.quantidade);

		if (percentualTotal < 100 && total > 0) {
			agrupadores = agrupadores.map((agrupador, i) => ({
				...agrupador,
				percentual: agrupador.percentual + (i == 0 ? 100 - percentualTotal : 0)
			}));
		}

		return this.componenteAnalise('Área', 'Por Área Responsável', agrupadores);

	}

	analiseCategoria(lista) {

		let agrupadores = [];
		lista.forEach(item => {
			if (item.nome_categoria && !agrupadores.filter(agrupador => agrupador.nome == item.nome_categoria).length) {
				agrupadores.push({
					nome: item.nome_categoria,
					cor: item.cor_categoria,
					quantidade: 0,
					percentual: 0
				});
			}
		});
		let total = lista.length;
		let percentualTotal = 0;
		if (total > 0) {
			agrupadores = agrupadores.map(agrupador => {
				let quantidade = lista.filter(item => item.nome_categoria == agrupador.nome).length;
				percentualTotal += parseInt((quantidade * 100) / total);

				return {
					...agrupador,
					quantidade: quantidade,
					percentual: parseInt((quantidade * 100) / total)
				}
			});
		}
		

		agrupadores.sort((a, b) => b.quantidade - a.quantidade);

		if (percentualTotal < 100 && total > 0) {
			agrupadores = agrupadores.map((agrupador, i) => ({
				...agrupador,
				percentual: agrupador.percentual + (i == 0 ? 100 - percentualTotal : 0)
			}));
		}

		return this.componenteAnalise('Categoria', 'Por Categoria', agrupadores);

	}

	analiseTipoOcorrencia(lista) {

		let agrupadores = [];
		lista.forEach(item => {
			if (item.nome_tipo && !agrupadores.filter(agrupador => agrupador.nome == item.nome_tipo).length) {
				agrupadores.push({
					nome: item.nome_tipo,
					quantidade: 0,
					percentual: 0
				});
			}
		});
		let total = lista.length;
		let percentualTotal = 0;
		if (total > 0) {
			agrupadores = agrupadores.map(agrupador => {
				let quantidade = lista.filter(item => item.nome_tipo == agrupador.nome).length;
				percentualTotal += parseInt((quantidade * 100) / total);

				return {
					...agrupador,
					quantidade: quantidade,
					percentual: parseInt((quantidade * 100) / total)
				}
			});
		}
		

		agrupadores.sort((a, b) => b.quantidade - a.quantidade);

		if (percentualTotal < 100 && total > 0) {
			agrupadores = agrupadores.map((agrupador, i) => ({
				...agrupador,
				percentual: agrupador.percentual + (i == 0 ? 100 - percentualTotal : 0)
			}));
		}

		return this.componenteAnalise('Tipo de Ocorrência', 'Por Tipo de Ocorrência', agrupadores);

	}

	analiseResponsavel(lista) {

		let agrupadores = [];
		lista.forEach(item => {
			if (item.nome_responsavel && !agrupadores.filter(agrupador => agrupador.nome == item.nome_responsavel).length) {
				agrupadores.push({
					nome: item.nome_responsavel,
					quantidade: 0,
					percentual: 0
				});
			}
		});
		let total = lista.length;
		let percentualTotal = 0;
		if (total > 0) {
			agrupadores = agrupadores.map(agrupador => {
				let quantidade = lista.filter(item => item.nome_responsavel == agrupador.nome).length;
				percentualTotal += parseInt((quantidade * 100) / total);

				return {
					...agrupador,
					quantidade: quantidade,
					percentual: parseInt((quantidade * 100) / total)
				}
			});
		}
		

		agrupadores.sort((a, b) => b.quantidade - a.quantidade);

		if (percentualTotal < 100 && total > 0) {
			agrupadores = agrupadores.map((agrupador, i) => ({
				...agrupador,
				percentual: agrupador.percentual + (i == 0 ? 100 - percentualTotal : 0)
			}));
		}

		return this.componenteAnalise('Responsável', 'Por Responsável', agrupadores);

	}

	componenteAnalise(nome, descricao, lista) {

		let acumulado = 0;
		let maior = 0;
		let quantidadeTotal = 0;
		lista.forEach(item => {
			if (item.quantidade > maior) {
				maior = item.quantidade;
			}
		});

		return (
			<Table responsive>
				<THead>
					<Tr>
						<Th width={100}>{nome}</Th>
						<Th width={30} alignCenter>Qtd.</Th>
						<Th width={30} alignCenter title='Percentual'>%</Th>
						<Th width={30} alignCenter title='Percentual Acumulado'>% Ac</Th>
						<Th width={150}>{descricao}</Th>
					</Tr>
				</THead>
				<TBody>
					{lista.map(item => {

						acumulado += item.percentual;
						quantidadeTotal += item.quantidade;

						return (
							<Tr key={item.nome}>
								<Td small noWrap>{item.nome}</Td>
								<Td alignCenter minWidth={30} small noWrap>{item.quantidade}</Td>
								<Td alignCenter minWidth={30} small noWrap>{item.percentual || '-'}</Td>
								<Td alignCenter minWidth={30} small noWrap>{acumulado}</Td>
								<Td small noWrap>
									<div 
										style={{ 
											width: `${maior == 0 ? 0 : ((item.quantidade * 100) / maior)}%`,
											height: 14,
											borderRadius: 10,
											backgroundColor: item.cor || '#1F3066'
										}}>

									</div>
								</Td>
							</Tr>
						);
					})}
					<Tr>
						<Td small noWrap bold>Total</Td>
						<Td alignCenter minWidth={30} small noWrap bold>{quantidadeTotal}</Td>
						<Td alignCenter minWidth={30} small noWrap bold>100</Td>
						<Td alignCenter minWidth={30} small noWrap bold>100</Td>
						<Td small noWrap>
						</Td>
					</Tr>
				</TBody>
			</Table>
		)

	}

	eventoAlterar(item) {

		//Verificar para marcar o visualizado pelo responsável
		if (!item.datahora_visualizacao_responsavel && item.id_usuario_responsavel == this.props.sessao.id) {
			this.props.alterarOcorrenciaVisualizadaResponsavel({
				...item	
			});
		}
		this.props.setModoTela('cadastro', {
			...item,
			datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
			datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
			percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
			datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
			datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
			datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
			custo: FormatUtils.formatarValorTela(item.custo, 2),
			valor: FormatUtils.formatarValorTela(item.valor, 2),
			valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
		});
		this.props.initForm({
			...item,
			datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
			datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
			percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
			datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
			datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
			datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
			custo: FormatUtils.formatarValorTela(item.custo, 2),
			valor: FormatUtils.formatarValorTela(item.valor, 2),
			valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
		});
	}

	eventoExcluir(item) {
		this.props.setModoTela('exclusao', {
			...item,
			datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
			datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
			percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
			datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
			datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
			datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
			custo: FormatUtils.formatarValorTela(item.custo, 2),
			valor: FormatUtils.formatarValorTela(item.valor, 2),
			valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
		});
		this.props.initForm({
			...item,
			datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
			datahora_descarga: DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga),
			percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
			datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
			datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_concluido),
			datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
			custo: FormatUtils.formatarValorTela(item.custo, 2),
			valor: FormatUtils.formatarValorTela(item.valor, 2),
			valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
		});
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.ocorrencia.aguardando,
	modoTela: state.ocorrencia.modoTela,
	lista: state.ocorrencia.lista,
	listaAnalise: state.ocorrencia.listaAnalise,
	filtro: state.ocorrencia.filtro,
	listaTipo: state.ocorrencia.listaTipo,
	listaMotorista: state.ocorrencia.listaMotorista,
	listaResponsabilidade: state.ocorrencia.listaResponsabilidade,
	listaStatus: state.ocorrencia.listaStatus,
	listaCategoria: state.ocorrencia.listaCategoria,
	listaOperacao: state.ocorrencia.listaOperacao,
	listaUsuario: state.ocorrencia.listaUsuario,
	listaClasse: state.ocorrencia.listaClasse
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, 
	getListaTipo, getListaMotorista, getListaGravidade, getListaLocal, 
	getListaResponsabilidade, getListaTipoCusto, salvarAbona, 
	getListaStatus, getListaAcao, getListaUsuario, getListaCategoria,
	getListaOperacao, getListaClasse, alterarOcorrenciaVisualizadaResponsavel,
	getListaAnalise
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OcorrenciaDocumentoViagem);
