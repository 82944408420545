const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    lista: [],
    listaPerfil: [],
    listaNegociador: [],
    listaOperacoes: [],
    listaMenu: [],
    listaUsuarioMenu: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'USUARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'USUARIO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'USUARIO_PERFIL_SELECT_LISTADO':
			return {
				...state,
				listaPerfil: action.payload.data
			};

        case 'USUARIO_NEGOCIADOR_LISTADO':
            return { ...state, listaNegociador: (action.payload.data || []).map(item => ({ id: parseInt(item.id_negociador), valor: item.negociador, operacoes: item.operacoes })) };

        case 'USUARIO_OPERACAO_LISTADO':
            return {
                ...state,
                listaOperacoes: (action.payload.data || []).filter(item => item.id != 575).map(item => ({ id: parseInt(item.codigo), valor: item.valor })) //EXCECAO_GERAL
            };
        
        case 'USUARIO_MENU_SELECT_LISTADO':
            return {
                ...state,
                listaMenu: action.payload.data
            }
        
        case 'USUARIO_MENU_LISTADO':
            return {
                ...state,
                listaUsuarioMenu: action.payload.data
            }

        default:
            return state;
    }
}
