import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Aguardando from '../common/template/aguardando';
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";
import Cookies from 'js-cookie';
import consts from '../consts';
import iconeBandeira from '../assets/images/icone_bandeira.png';
import iconeViagemAberta from '../assets/images/icone_viagem_aberta.png';
import iconeRelogio from '../assets/images/icone_relogio.png';
import iconeCaminhaoVerde from '../assets/map/marker_truck_direita_success.png';
import iconeCaminhaoVermelho from '../assets/map/marker_truck_direita_danger.png';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { 
	setModoTela, setFiltro, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista, getListaOcorrencias
} from './reportsTrackActions';
import { registrarAcesso } from '../analiseAcesso/analiseAcessoActions';
import Select from '../common/form/select';
import SelectNovo from '../common/form/selectNovo';
import SwitchVertical from '../common/form/switchVertical';

class ReportsTrack extends Component {

	state = {
		modoTela: 'lista',
		linhaSelecionada: null,
		pesquisar: '',
		pesquisarAux: '',
		idShippingExpandido: null,
		resumido: true,
		registroModal: null,
		registroModalOcorrencia: null,
		listaFavoritos: JSON.parse(Cookies.get('shippingsFavoritos') || '[]'),
		listaPeriodo: [
			{ id: 'DATA_PREVISAO_CARGA', valor: 'Previsão Carga' },
			{ id: 'DATA_INICIO_CARGA', valor: 'Chegada Carga' },
			{ id: 'DATA_TERMINO_CARGA', valor: 'Saída Carga' },
			{ id: 'DATA_ENTRADA_ADUANA_ORIGEM', valor: 'Chegada Aduana Origem' },
			{ id: 'DATA_LIBERACAO_ADUANA_ORIGEM', valor: 'Liberação Aduana Origem' },
			{ id: 'DATA_PREV_FRONTEIRA', valor: 'Previsão Fronteira' },
			{ id: 'DATA_ENTRADA_FRONTEIRA', valor: 'Chegada Fronteira' },
			{ id: 'DATA_AUTORIZACAO_INGRESSO_FRONTEIRA', valor: 'Autorização Ingresso fronteira' },
			{ id: 'DT_ENTRADA_ADUANA_FRONT_ORG', valor: 'Chegada Aduana Fronteira Origem' },
			{ id: 'DT_LIBERACAO_ADUANA_FRONT_ORG', valor: 'Liberação Aduana Fronteira Origem' },
			{ id: 'DT_ENTRADA_ADUANA_FRONT_DEST', valor: 'Chegada Aduana Fronteira Destino' },
			{ id: 'DT_LIBERACAO_ADUANA_FRONT_DEST', valor: 'Liberação Aduana Fronteira Destino' },
			{ id: 'DATA_LIBERACAO_FRONTEIRA', valor: 'Liberação Fronteira	' },
			{ id: 'DATA_ENTRADA_ADUANA_DESTINO', valor: 'Chegada Aduana Destino' },
			{ id: 'DATA_LIBERACAO_ADUANA_DESTINO', valor: 'Liberação Aduana Destino' },
			{ id: 'DATA_PREVISAO_DESCARGA', valor: 'Previsão Descarga' },
			{ id: 'DATA_INICIO_DESCARGA', valor: 'Chegada Descarga' },
			{ id: 'DATA_TERMINO_DESCARGA', valor: 'Saída Descarga' }
		],
		filtro: {
			tipo_periodo: 'DATA_PREVISAO_CARGA',
			data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(31)),
			data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			aberto: true,
			finalizado: false,
			semNaoTrack: false,
			comNaoTrack: true,
			/*rumos: [],
			farolAtrasos: [],
			fronteiras: [],
			operacoes: [],
			negociadores: [],
			lote: false,
			classificada: false,
			expressa: false,			
			ocorrenciaAberta: false,
			cavaloEngatado: false,
			somenteCarreta: false,
			semConjunto: false,
			emCD: false,
			semDocumento: false,*/
			nacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.nacional : false,
			internacional: this.props.usuarioCarregado ? this.props.usuarioCarregado.internacional : false,
			ocorrencia_aberta: false,
			transit_time_atraso: false,
			classificada: false
		},
		ordenacao: {
			ocorrencia: 0,
			transit_time: 2,
			shipping: 0,
			solicitacao: 0,
			classificada: 0,
			previsao_carga: 0,
			inicio_carga: 0,
			termino_carga: 0,
			entrada_aduana_origem: 0,
			liberacao_aduana_origem: 0,
			previsao_fronteira: 0,
			entrada_fronteira: 0,
			autorizacao_ingresso_fronteira: 0,
			entrada_aduana_front_org: 0,
			liberacao_aduana_front_org: 0,
			entrada_aduana_front_dest: 0,
			liberacao_aduana_front_dest: 0,
			liberacao_fronteira: 0,
			entrada_aduana_destino: 0,
			liberacao_aduana_destino: 0,
			previsao_descarga: 0,
			inicio_descarga: 0,
			termino_descarga: 0,
			fatura: 0,
			observacao: 0,
			documento: 0,
			cavalo: 0,
			carreta: 0,
			cliente: 0,
			remetente: 0,
			destinatario: 0,
			rota: 0,
			fronteira: 0,
			indicador_carga: 0,
			indicador_fronteira: 0,
			indicador_descarga: 0,
			indicador_permanencia_carga: 0,
			indicador_permanencia_fronteira: 0,
			indicador_permanencia_descarga: 0,
			lead_time: 0,

			/*documentos: 0,
			cavalo: 0,
			carreta: 0,
			lote: 0,
			classificada: 0,
			expressa: 0,
			ocorrencia: 0,
			cliente: 0,
			rota: 0,
			fronteira: 0,
			previsao: 0,
			statusPrevisao: 0,
			posicao: 0,
			statusPosicao: 0,
			destinado: 0*/
		}
	}

    componentWillMount() {
		this.props.getListaFronteiras();
		this.props.getListaNegociadores();
		this.props.getListaOperacoes();
        this.props.getLista(this.state.filtro);

		setInterval(() => {
			this.props.getListaFronteiras();
			this.props.getListaNegociadores();
			this.props.getListaOperacoes();
			this.props.getLista(this.state.filtro);
		}, 120000)
    }

	componentDidMount() {
		//Início do registro de Análise Tela
		this.timeEntrouTela = Date.now();
		this.idEntrouTela = setInterval(() => {
			this.props.registrarAcesso('Track 2.0', this.props.location.pathname, Date.now() - this.timeEntrouTela);
			this.timeEntrouTela = Date.now();
		}, 60000);
		//Fim do registro de Análise Tela
	}
	
	componentWillUnmount() {
		//Início do encerramento de Análise Tela
		clearInterval(this.idEntrouTela);
		this.props.registrarAcesso('Track 2.0', this.props.location.pathname, Date.now() - this.timeEntrouTela);
		//Fim do encerramento de Análise Tela
	}

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.montarTela()}

                </Content>
            </div>
        )
    }

	filtrar() {

		let acessoCliente = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente;

		let lista = this.props.lista

			.filter(item => {
				if (this.props.usuarioCarregado && (this.props.usuarioCarregado.placas_cco || []).length > 0) {
					return (this.props.usuarioCarregado.placas_cco || []).filter(placa => placa == item.cavalo).length > 0;
				} else {
					return this.props.usuarioCarregado ? true : false;
				}
			})

			.filter(item => {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente) {
					return (this.props.usuarioCarregado.id_negociadores || []).filter(negociador => negociador == item.id_negociador).length > 0;
				} else {
					return true;
				}
			})

			.filter(item => {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente && (this.props.usuarioCarregado.id_operacoes || []).length > 0) {
					return (this.props.usuarioCarregado.id_operacoes || []).filter(operacao => operacao == item.operacao_id).length > 0;
				} else {
					return true;
				}
			})

			//FILTROS SELECT
			.filter(item => {

				/*if ((this.state.filtro.rumos || []).length && !this.state.filtro.rumos.filter(itemFiltro => itemFiltro == item.rumo).length) {
					return false;
				}
				if ((this.state.filtro.farolAtrasos || []).length 
					&& !this.state.filtro.farolAtrasos.filter(itemFiltro => 
						itemFiltro == 'ADIANTADO' && item.status == 1
						|| itemFiltro == 'ATRASADO' && (item.status == 2 || item.status == 3)
						|| itemFiltro == 'NENHUM' && !item.status
					).length) {
					return false;
				}
				if ((this.state.filtro.fronteiras || []).length && !this.state.filtro.fronteiras.filter(itemFiltro => itemFiltro == item.id_fronteira_ref).length) {
					return false;
				}
				if ((this.state.filtro.operacoes || []).length 
					&& !this.state.filtro.operacoes.filter(itemFiltro => 
						(this.props.listaOperacoes.filter(operacao => operacao.id == itemFiltro)[0] ? this.props.listaOperacoes.filter(operacao => operacao.id == itemFiltro)[0].valor : null)
						 == item.descricao_operacao).length) {
					return false;
				}
				if ((this.state.filtro.negociadores || []).length && !this.state.filtro.negociadores.filter(itemFiltro => itemFiltro == item.id_negociador).length) {
					return false;
				}*/

				return true;
			})

			//FILTROS SWITCH
			.filter(item => {
				if (!this.state.filtro.aberto || !this.state.filtro.finalizado) {
					if (!this.state.filtro.aberto && !this.state.filtro.finalizado) {
						return false;
					} else if (this.state.filtro.aberto && item.finalizado) {
						return false;
					} else if (this.state.filtro.finalizado && !item.finalizado) {
						return false;
					}					
				}
				
				/*
				if (this.state.filtro.lote && !item.lote) {
					return false;
				}
				if (this.state.filtro.classificada && !item.classificada) {
					return false;
				}
				if (this.state.filtro.expressa && !item.expressa) {
					return false;
				}
				if (this.state.filtro.ocorrenciaAberta && !item.ocorrencias_abertas) {
					return false;
				}
				if (this.state.filtro.cavaloEngatado && !item.cavalo) {
					return false;
				}
				if (this.state.filtro.somenteCarreta && !item.carreta) {
					return false;
				}
				if (this.state.filtro.semConjunto && (item.cavalo || item.carreta)) {
					return false;
				}
				if (this.state.filtro.semDocumento && item.qtd_documentos > 0) {
					return false;
				}*/
				if (!this.state.filtro.nacional || !this.state.filtro.internacional) {
					//Se não tem rumo significa que ainda não foram registrados eventos no shipping
					if (!this.state.filtro.nacional && !this.state.filtro.internacional) {
						return item.rumo == 'INDEFINIDO';
					}
					if (this.state.filtro.nacional && item.rumo != 'NAC') {
						return false;
					}
					if (this.state.filtro.internacional && item.rumo == 'NAC') {
						return false;
					}
				}
				
				if (this.state.filtro.ocorrencia_aberta && item.ocorrencias_abertas <= 0) {
					return false;
				}


				let transitTimeAtraso = false;
				if (item.transit_time < 100 && item.datahora_previsao_real) {
					if (!item.data_inicio_carga) {
						if (item.data_previsao_carga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_carga).getTime()) {
							transitTimeAtraso = true;
						}
					} else if (item.fronteira && item.data_termino_carga && !item.data_entrada_fronteira) {
						if (item.data_prev_fronteira && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_prev_fronteira).getTime()) {
							transitTimeAtraso = true;
						}
					} else if (((!item.fronteira && item.data_termino_carga) || (item.fronteira && item.data_liberacao_fronteira)) && !item.data_inicio_descarga) {
						if (item.data_previsao_descarga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_descarga).getTime()) {
							transitTimeAtraso = true;
						}
					}
				}

				if (this.state.filtro.transit_time_atraso && !transitTimeAtraso) {
					return false;
				}

				if (this.state.filtro.classificada && !item.classificada) {
					return false;
				}
				
				return true;

			})
			//FILTRO BUSCA TEXTO
			.filter(item => {

				return !(this.state.pesquisar || '').trim()
					|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {
					if (pesquisar
						&& !(`
							${item.shipping_code_id || ''}
							${item.solicitacao_carga_id || ''}
							${item.fatura || ''}
							${item.observacao || ''}
							${item.documento || ''}
							${item.cavalo || ''}
							${item.carreta || ''}
							${!acessoCliente ? item.negociador || '' : ''}
							${acessoCliente ? item.nome_remetente_sol || '' : ''}
							${acessoCliente ? item.nome_destinatario_sol || '' : ''}
							${item.rota || ''}
							${item.rumo || ''}
							${item.nome_fronteira || ''}
						`).toUpperCase().includes(pesquisar)) {
						return false;
					}

					return true;

				}).length > 0;

			});

			if (this.state.ordenacao.ocorrencia) {
				// lista.sort((a, b) => this.state.ordenacao.ocorrencia == 1 
				// 	? (a.ocorrencias_abertas > b.ocorrencias_abertas ? 1 : -1) 
				// 	: (a.ocorrencias_abertas > b.ocorrencias_abertas ? -1 : 1));
			} else if (this.state.ordenacao.transit_time) {
				lista.sort((a, b) => this.state.ordenacao.transit_time == 1 
					? (parseInt(a.transit_time) > parseInt(b.transit_time) ? 1 : -1) 
					: (parseInt(a.transit_time) > parseInt(b.transit_time) ? -1 : 1));
			} else if (this.state.ordenacao.shipping) {
				lista.sort((a, b) => this.state.ordenacao.shipping == 1 
					? (parseInt(a.shipping_code_id) > parseInt(b.shipping_code_id) ? 1 : -1) 
					: (parseInt(a.shipping_code_id) > parseInt(b.shipping_code_id) ? -1 : 1));
			} else if (this.state.ordenacao.solicitacao) {
				lista.sort((a, b) => this.state.ordenacao.solicitacao == 1 
					? (parseInt(a.solicitacao_carga_id) > parseInt(b.solicitacao_carga_id) ? 1 : -1) 
					: (parseInt(a.solicitacao_carga_id) > parseInt(b.solicitacao_carga_id) ? -1 : 1));
			} else if (this.state.ordenacao.classificada) {
				lista.sort((a, b) => this.state.ordenacao.classificada == 1 
					? (parseInt(a.classificada ? 0 : 1) > parseInt(b.classificada ? 0 : 1) ? 1 : -1) 
					: (parseInt(a.classificada ? 0 : 1) > parseInt(b.classificada ? 0 : 1) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_carga) {
				lista.sort((a, b) => this.state.ordenacao.previsao_carga == 1 
					? (new Date(a.data_previsao_carga || null) > new Date(b.data_previsao_carga || null) ? 1 : -1)
					: (new Date(a.data_previsao_carga || null) > new Date(b.data_previsao_carga || null) ? -1 : 1));
			} else if (this.state.ordenacao.inicio_carga) {
				lista.sort((a, b) => this.state.ordenacao.inicio_carga == 1 
					? (new Date(a.data_inicio_carga || null) > new Date(b.data_inicio_carga || null) ? 1 : -1)
					: (new Date(a.data_inicio_carga || null) > new Date(b.data_inicio_carga || null) ? -1 : 1));
			} else if (this.state.ordenacao.termino_carga) {
				lista.sort((a, b) => this.state.ordenacao.termino_carga == 1 
					? (new Date(a.data_termino_carga || null) > new Date(b.data_termino_carga || null) ? 1 : -1)
					: (new Date(a.data_termino_carga || null) > new Date(b.data_termino_carga || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_origem) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_origem == 1 
					? (new Date(a.data_entrada_aduana_origem || null) > new Date(b.data_entrada_aduana_origem || null) ? 1 : -1)
					: (new Date(a.data_entrada_aduana_origem || null) > new Date(b.data_entrada_aduana_origem || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_origem) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_origem == 1 
					? (new Date(a.data_liberacao_aduana_origem || null) > new Date(b.data_liberacao_aduana_origem || null) ? 1 : -1)
					: (new Date(a.data_liberacao_aduana_origem || null) > new Date(b.data_liberacao_aduana_origem || null) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.previsao_fronteira == 1 
					? (new Date(a.data_prev_fronteira || null) > new Date(b.data_prev_fronteira || null) ? 1 : -1)
					: (new Date(a.data_prev_fronteira || null) > new Date(b.data_prev_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.entrada_fronteira == 1 
					? (new Date(a.data_entrada_fronteira || null) > new Date(b.data_entrada_fronteira || null) ? 1 : -1)
					: (new Date(a.data_entrada_fronteira || null) > new Date(b.data_entrada_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.autorizacao_ingresso_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.autorizacao_ingresso_fronteira == 1 
					? (new Date(a.data_autorizacao_ingresso_fronteira || null) > new Date(b.data_autorizacao_ingresso_fronteira || null) ? 1 : -1)
					: (new Date(a.data_autorizacao_ingresso_fronteira || null) > new Date(b.data_autorizacao_ingresso_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_front_org) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_front_org == 1 
					? (new Date(a.dt_entrada_aduana_front_org || null) > new Date(b.dt_entrada_aduana_front_org || null) ? 1 : -1)
					: (new Date(a.dt_entrada_aduana_front_org || null) > new Date(b.dt_entrada_aduana_front_org || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_front_org) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_front_org == 1 
					? (new Date(a.dt_liberacao_aduana_front_org || null) > new Date(b.dt_liberacao_aduana_front_org || null) ? 1 : -1)
					: (new Date(a.dt_liberacao_aduana_front_org || null) > new Date(b.dt_liberacao_aduana_front_org || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_front_dest) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_front_dest == 1 
					? (new Date(a.dt_entrada_aduana_front_dest || null) > new Date(b.dt_entrada_aduana_front_dest || null) ? 1 : -1)
					: (new Date(a.dt_entrada_aduana_front_dest || null) > new Date(b.dt_entrada_aduana_front_dest || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_front_dest) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_front_dest == 1 
					? (new Date(a.dt_liberacao_aduana_front_dest || null) > new Date(b.dt_liberacao_aduana_front_dest || null) ? 1 : -1)
					: (new Date(a.dt_liberacao_aduana_front_dest || null) > new Date(b.dt_liberacao_aduana_front_dest || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_fronteira) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_fronteira == 1 
					? (new Date(a.data_liberacao_fronteira || null) > new Date(b.data_liberacao_fronteira || null) ? 1 : -1)
					: (new Date(a.data_liberacao_fronteira || null) > new Date(b.data_liberacao_fronteira || null) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_aduana_destino) {
				lista.sort((a, b) => this.state.ordenacao.previsao_aduana_destino == 1 
					? (new Date(a.data_prev_aduana_destino || null) > new Date(b.data_prev_aduana_destino || null) ? 1 : -1)
					: (new Date(a.data_prev_aduana_destino || null) > new Date(b.data_prev_aduana_destino || null) ? -1 : 1));
			} else if (this.state.ordenacao.entrada_aduana_destino) {
				lista.sort((a, b) => this.state.ordenacao.entrada_aduana_destino == 1 
					? (new Date(a.data_entrada_aduana_destino || null) > new Date(b.data_entrada_aduana_destino || null) ? 1 : -1)
					: (new Date(a.data_entrada_aduana_destino || null) > new Date(b.data_entrada_aduana_destino || null) ? -1 : 1));
			} else if (this.state.ordenacao.liberacao_aduana_destino) {
				lista.sort((a, b) => this.state.ordenacao.liberacao_aduana_destino == 1 
					? (new Date(a.data_liberacao_aduana_destino || null) > new Date(b.data_liberacao_aduana_destino || null) ? 1 : -1)
					: (new Date(a.data_liberacao_aduana_destino || null) > new Date(b.data_liberacao_aduana_destino || null) ? -1 : 1));
			} else if (this.state.ordenacao.previsao_descarga) {
				lista.sort((a, b) => this.state.ordenacao.previsao_descarga == 1 
					? (new Date(a.data_previsao_descarga || null) > new Date(b.data_previsao_descarga || null) ? 1 : -1)
					: (new Date(a.data_previsao_descarga || null) > new Date(b.data_previsao_descarga || null) ? -1 : 1));
			} else if (this.state.ordenacao.inicio_descarga) {
				lista.sort((a, b) => this.state.ordenacao.inicio_descarga == 1 
					? (new Date(a.data_inicio_descarga || null) > new Date(b.data_inicio_descarga || null) ? 1 : -1)
					: (new Date(a.data_inicio_descarga || null) > new Date(b.data_inicio_descarga || null) ? -1 : 1));
			} else if (this.state.ordenacao.termino_descarga) {
				lista.sort((a, b) => this.state.ordenacao.termino_descarga == 1 
					? (new Date(a.data_termino_descarga || null) > new Date(b.data_termino_descarga || null) ? 1 : -1)
					: (new Date(a.data_termino_descarga || null) > new Date(b.data_termino_descarga || null) ? -1 : 1));
			} else if (this.state.ordenacao.cliente) {
				lista.sort((a, b) => this.state.ordenacao.cliente == 1 
					? (a.negociador > b.negociador ? 1 : -1) 
					: (a.negociador > b.negociador ? -1 : 1));
			} else if (this.state.ordenacao.remetente) {
				lista.sort((a, b) => this.state.ordenacao.remetente == 1 
					? (a.nome_remetente_sol > b.nome_remetente_sol ? 1 : -1) 
					: (a.nome_remetente_sol > b.nome_remetente_sol ? -1 : 1));
			} else if (this.state.ordenacao.destinatario) {
				lista.sort((a, b) => this.state.ordenacao.destinatario == 1 
					? (a.nome_destinatario_sol > b.nome_destinatario_sol ? 1 : -1) 
					: (a.nome_destinatario_sol > b.nome_destinatario_sol ? -1 : 1));
			} else if (this.state.ordenacao.rota) {
				lista.sort((a, b) => this.state.ordenacao.rota == 1 
					? (a.rota > b.rota ? 1 : -1) 
					: (a.rota > b.rota ? -1 : 1));
			} else if (this.state.ordenacao.observacao) {
				lista.sort((a, b) => this.state.ordenacao.observacao == 1 
					? ((a.observacao || '') > (b.observacao || '') ? 1 : -1) 
					: ((a.observacao || '') > (b.observacao || '') ? -1 : 1));
			} else if (this.state.ordenacao.fatura) {
				lista.sort((a, b) => this.state.ordenacao.fatura == 1 
					? ((a.fatura || '') > (b.fatura || '') ? 1 : -1) 
					: ((a.fatura || '') > (b.fatura || '') ? -1 : 1));
			} else if (this.state.ordenacao.documento) {
				lista.sort((a, b) => this.state.ordenacao.documento == 1 
					? ((a.documento || '0') > (b.documento || '0') ? 1 : -1) 
					: ((a.documento || '0') > (b.documento || '0') ? -1 : 1));
			} else if (this.state.ordenacao.cavalo) {
				lista.sort((a, b) => this.state.ordenacao.cavalo == 1 
					? ((a.cavalo || '0') > (b.cavalo || '0') ? 1 : -1) 
					: ((a.cavalo || '0') > (b.cavalo || '0') ? -1 : 1));
			} else if (this.state.ordenacao.carreta) {
				lista.sort((a, b) => this.state.ordenacao.carreta == 1 
					? ((a.carreta || '0') > (b.carreta || '0') ? 1 : -1) 
					: ((a.carreta || '0') > (b.carreta || '0') ? -1 : 1));
			} else if (this.state.ordenacao.rumo) {
				lista.sort((a, b) => this.state.ordenacao.rumo == 1 
					? (a.rumo > b.rumo ? 1 : -1) 
					: (a.rumo > b.rumo ? -1 : 1));
			} else if (this.state.ordenacao.fronteira) {
				lista.sort((a, b) => this.state.ordenacao.fronteira == 1 
					? ((a.nome_fronteira, 'a') > (b.nome_fronteira, 'a') ? 1 : -1) 
					: ((a.nome_fronteira, 'a') > (b.nome_fronteira, 'a') ? -1 : 1));			
			} else if (this.state.ordenacao.indicador_carga) {
				lista.sort((a, b) => {

					const minutosA = !a.data_previsao_carga ? -10000 : (parseInt(a.chegada_carga_dias || 0) * 24 * 60) + (parseInt(a.chegada_carga_horas || 0) * 60) + parseInt(a.chegada_carga_minutos || 0);
					const minutosB = !b.data_previsao_carga ? -10000 : (parseInt(b.chegada_carga_dias || 0) * 24 * 60) + (parseInt(b.chegada_carga_horas || 0) * 60) + parseInt(b.chegada_carga_minutos || 0);

					return this.state.ordenacao.indicador_carga == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_fronteira) {
				lista.sort((a, b) => {

					const minutosA = !a.data_prev_fronteira ? -10000 : (parseInt(a.chegada_fronteira_dias || 0) * 24 * 60) + (parseInt(a.chegada_fronteira_horas || 0) * 60) + parseInt(a.chegada_fronteira_minutos || 0);
					const minutosB = !b.data_prev_fronteira ? -10000 : (parseInt(b.chegada_fronteira_dias || 0) * 24 * 60) + (parseInt(b.chegada_fronteira_horas || 0) * 60) + parseInt(b.chegada_fronteira_minutos || 0);

					return this.state.ordenacao.indicador_fronteira == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_aduana_destino) {
				lista.sort((a, b) => {

					const minutosA = !a.data_prev_aduana_destino ? -10000 : (parseInt(a.chegada_aduana_destino_dias || 0) * 24 * 60) + (parseInt(a.chegada_aduana_destino_horas || 0) * 60) + parseInt(a.chegada_aduana_destino_minutos || 0);
					const minutosB = !b.data_prev_aduana_destino ? -10000 : (parseInt(b.chegada_aduana_destino_dias || 0) * 24 * 60) + (parseInt(b.chegada_aduana_destino_horas || 0) * 60) + parseInt(b.chegada_aduana_destino_minutos || 0);

					return this.state.ordenacao.indicador_aduana_destino == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_descarga) {
				lista.sort((a, b) => {

					const minutosA = !a.data_previsao_descarga ? -10000 : (parseInt(a.chegada_descarga_dias || 0) * 24 * 60) + (parseInt(a.chegada_descarga_horas || 0) * 60) + parseInt(a.chegada_descarga_minutos || 0);
					const minutosB = !b.data_previsao_descarga ? -10000 : (parseInt(b.chegada_descarga_dias || 0) * 24 * 60) + (parseInt(b.chegada_descarga_horas || 0) * 60) + parseInt(b.chegada_descarga_minutos || 0);

					return this.state.ordenacao.indicador_descarga == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_permanencia_carga) {
				lista.sort((a, b) => {

					const minutosA = !a.data_inicio_carga ? -0.0001 : (parseInt(a.janela_carga_dias || 0) * 24 * 60) + (parseInt(a.janela_carga_horas || 0) * 60) + parseInt(a.janela_carga_minutos || 0);
					const minutosB = !b.data_inicio_carga ? -0.0001 : (parseInt(b.janela_carga_dias || 0) * 24 * 60) + (parseInt(b.janela_carga_horas || 0) * 60) + parseInt(b.janela_carga_minutos || 0);

					return this.state.ordenacao.indicador_permanencia_carga == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_permanencia_fronteira) {
				lista.sort((a, b) => {

					const minutosA = !a.data_entrada_fronteira ? -0.0001 : (parseInt(a.janela_fronteira_dias || 0) * 24 * 60) + (parseInt(a.janela_fronteira_horas || 0) * 60) + parseInt(a.janela_fronteira_minutos || 0);
					const minutosB = !b.data_entrada_fronteira ? -0.0001 : (parseInt(b.janela_fronteira_dias || 0) * 24 * 60) + (parseInt(b.janela_fronteira_horas || 0) * 60) + parseInt(b.janela_fronteira_minutos || 0);

					return this.state.ordenacao.indicador_permanencia_fronteira == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_permanencia_aduana_destino) {
				lista.sort((a, b) => {

					const minutosA = !a.data_entrada_aduana_destino ? -0.0001 : (parseInt(a.janela_aduana_destino_dias || 0) * 24 * 60) + (parseInt(a.janela_aduana_destino_horas || 0) * 60) + parseInt(a.janela_aduana_destino_minutos || 0);
					const minutosB = !b.data_entrada_aduana_destino ? -0.0001 : (parseInt(b.janela_aduana_destino_dias || 0) * 24 * 60) + (parseInt(b.janela_aduana_destino_horas || 0) * 60) + parseInt(b.janela_aduana_destino_minutos || 0);

					return this.state.ordenacao.indicador_permanencia_aduana_destino == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.indicador_permanencia_descarga) {
				lista.sort((a, b) => {

					const minutosA = !a.data_inicio_descarga ? -0.0001 : (parseInt(a.janela_descarga_dias || 0) * 24 * 60) + (parseInt(a.janela_descarga_horas || 0) * 60) + parseInt(a.janela_descarga_minutos || 0);
					const minutosB = !b.data_inicio_descarga ? -0.0001 : (parseInt(b.janela_descarga_dias || 0) * 24 * 60) + (parseInt(b.janela_descarga_horas || 0) * 60) + parseInt(b.janela_descarga_minutos || 0);

					return this.state.ordenacao.indicador_permanencia_descarga == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			} else if (this.state.ordenacao.lead_time) {
				lista.sort((a, b) => {

					const minutosA = !a.data_inicio_carga ? -0.0001 : (parseInt(a.lead_time_dias || 0) * 24 * 60) + (parseInt(a.lead_time_horas || 0) * 60) + parseInt(a.lead_time_minutos || 0);
					const minutosB = !b.data_inicio_carga ? -0.0001 : (parseInt(b.lead_time_dias || 0) * 24 * 60) + (parseInt(b.lead_time_horas || 0) * 60) + parseInt(b.lead_time_minutos || 0);

					return this.state.ordenacao.lead_time == 1 
						? minutosA - minutosB 
						: minutosB - minutosA;
				});
			}
	
			/*else if (this.state.ordenacao.documentos) {
				lista.sort((a, b) => this.state.ordenacao.documentos == 1 
					? ((a.documentos || []).filter(documento => documento).join(', ') > (b.documentos || []).filter(documento => documento).join(', ') ? 1 : -1) 
					: ((a.documentos || []).filter(documento => documento).join(', ') > (b.documentos || []).filter(documento => documento).join(', ') ? -1 : 1));
			} else if (this.state.ordenacao.cavalo) {
				lista.sort((a, b) => this.state.ordenacao.cavalo == 1 
					? (a.cavalo > b.cavalo ? 1 : -1) 
					: (a.cavalo > b.cavalo ? -1 : 1));
			} else if (this.state.ordenacao.carreta) {
				lista.sort((a, b) => this.state.ordenacao.carreta == 1 
					? (a.carreta > b.carreta ? 1 : -1) 
					: (a.carreta > b.carreta ? -1 : 1));
			} else if (this.state.ordenacao.lote) {
				lista.sort((a, b) => this.state.ordenacao.lote == 1 
					? (a.lotes > b.lotes ? 1 : -1) 
					: (a.lotes > b.lotes ? -1 : 1));
			} else if (this.state.ordenacao.classificada) {
				lista.sort((a, b) => this.state.ordenacao.classificada == 1 
					? (a.classificada > b.classificada ? 1 : -1) 
					: (a.classificada > b.classificada ? -1 : 1));
			} else if (this.state.ordenacao.expressa) {
				lista.sort((a, b) => this.state.ordenacao.expressa == 1 
					? (a.expressa > b.expressa ? 1 : -1) 
					: (a.expressa > b.expressa ? -1 : 1));
			} else if (this.state.ordenacao.ocorrencia) {
				lista.sort((a, b) => this.state.ordenacao.ocorrencia == 1 
					? (a.ocorrencias_abertas > b.ocorrencias_abertas ? 1 : -1) 
					: (a.ocorrencias_abertas > b.ocorrencias_abertas ? -1 : 1));
			} else if (this.state.ordenacao.cliente) {
				lista.sort((a, b) => this.state.ordenacao.cliente == 1 
					? (a.negociador > b.negociador ? 1 : -1) 
					: (a.negociador > b.negociador ? -1 : 1));
			} else if (this.state.ordenacao.rota) {
				lista.sort((a, b) => this.state.ordenacao.rota == 1 
					? (`${a.cidade_origem_shipping} ${a.cidade_origem_shipping ? '-' : ''} ${a.cidade_destino_shipping}` > `${b.cidade_origem_shipping} ${b.cidade_origem_shipping ? '-' : ''} ${b.cidade_destino_shipping}` ? 1 : -1) 
					: (`${a.cidade_origem_shipping} ${a.cidade_origem_shipping ? '-' : ''} ${a.cidade_destino_shipping}` > `${b.cidade_origem_shipping} ${b.cidade_origem_shipping ? '-' : ''} ${b.cidade_destino_shipping}` ? -1 : 1));
			} else if (this.state.ordenacao.fronteira) {
				lista.sort((a, b) => this.state.ordenacao.fronteira == 1 
					? (a.fronteira > b.fronteira ? 1 : -1) 
					: (a.fronteira > b.fronteira ? -1 : 1));
			} else if (this.state.ordenacao.previsao) {
				lista.sort((a, b) => this.state.ordenacao.previsao == 1 
					? (a.datahora_previsao > b.datahora_previsao ? 1 : -1) 
					: (a.datahora_previsao > b.datahora_previsao ? -1 : 1));
			} else if (this.state.ordenacao.statusPrevisao) {
				
				lista.sort((a, b) => {
					let tempoA = a.id_status == 1 || a.id_status == 2 || a.id_status == 4 || a.id_status == 6 ? (
						((parseInt(a.previsao_diferenca_dias || 0) * 24) * 60) 
						+ (parseInt(a.previsao_diferenca_horas || 0) * 60) 
						+ parseInt(a.previsao_diferenca_minutos || 0)
					) : 0;

					let tempoB = b.id_status == 1 || b.id_status == 2 || b.id_status == 4 || b.id_status == 6 ? (
						((parseInt(b.previsao_diferenca_dias || 0) * 24) * 60) 
						+ (parseInt(b.previsao_diferenca_horas || 0) * 60) 
						+ parseInt(b.previsao_diferenca_minutos || 0)
					) : 0;

					if (this.state.ordenacao.statusPrevisao == 1) {
						return (tempoA > tempoB ? 1 : -1);
					} else {
						return (tempoA > tempoB ? -1 : 1);
					}
				});
			} else if (this.state.ordenacao.posicao) {
				lista.sort((a, b) => this.state.ordenacao.posicao == 1 
					? (a.veiculo_referencia > b.veiculo_referencia ? 1 : -1) 
					: (a.veiculo_referencia > b.veiculo_referencia ? -1 : 1));
			} else if (this.state.ordenacao.statusPosicao) {
				lista.sort((a, b) => this.state.ordenacao.statusPosicao == 1 
				? (a.status > b.status ? 1 : -1) 
				: (a.status > b.status ? -1 : 1));
			} else if (this.state.ordenacao.destinado) {

				lista.sort((a, b) => {

					let destinadoA = 1;
					let destinadoB = 1;
					if (a.id_status == 1) {
						destinadoA = a.statusShipping.datahora_destinado_programacao ? 1 : 3;
					} else if (a.id_status == 3) {
						destinadoA = a.statusShipping.datahora_destinado_primeiro_transito ? 1 : 3;
					} else if (a.id_status == 5) {
						destinadoA = a.statusShipping.datahora_destinado_segundo_transito ? 1 : 3;
					}
					if (b.id_status == 1) {
						destinadoB = b.statusShipping.datahora_destinado_programacao ? 1 : 3;
					} else if (b.id_status == 3) {
						destinadoB = b.statusShipping.datahora_destinado_primeiro_transito ? 1 : 3;
					} else if (b.id_status == 5) {
						destinadoB = b.statusShipping.datahora_destinado_segundo_transito ? 1 : 3;
					}
				
					return this.state.ordenacao.destinado == 1 
						? (destinadoA > destinadoB ? 1 : -1) 
						: (destinadoA > destinadoB ? -1 : 1);
				});
			}*/

			return lista;
	}

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getIconeOrdenacao(tipo) {
		if (tipo == 1) {
			return <i class="fas fa-sort-amount-down-alt" />;
		} else if (tipo == 2) {
			return <i class="fas fa-sort-amount-up" />;
		} else {
			return null;
		}
	}

	montarTela() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let acessoCliente = this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_cliente;
		let indicadores = this.props.usuarioCarregado && (!this.props.usuarioCarregado.acesso_cliente || this.props.usuarioCarregado.track_indicadores);

		let listaFiltrada = this.filtrar();

		const telaMobile = window.innerWidth < 1247;
		
		let setLinhaSelecionada = (id) => {
			this.setState({
				...this.state,
				linhaSelecionada: id
			});
		}

		return (
			<ContentCard>
				
				{this.state.registroModal ? this.modalShipping() : null}
				
				{this.state.registroModalOcorrencia ? this.modalOcorrencia() : null}

				{this.state.contatosModal ? this.modalContatos() : null}

				<ContentCardHeader paddingBottom={0}>
					
					<Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
						<Grid cols='12 12 2 2' style={{ textAlign: 'right' }}>

							{this.props.usuarioCarregado && this.props.usuarioCarregado.logo &&
							<img src={`${consts.URL}/${this.props.usuarioCarregado.logo}`} style={{ marginRight: 10, marginTop: 10, height: 55 }} />}
						</Grid>
						
						<Grid cols='12 12 10 10'>
							
							<Row>					
								{this.state.modoTela != 'bi' &&
								<div style={{ width: 320, marginLeft: 4 }}>
									<div class='form-group'>
										<label htmlFor='pesquisa_geral'>Pesquisar</label>
										<div class='input-group' id='pesquisar'>
											<input 
												type='text'
												name='pesquisa_geral'
												class='form-control datetimepicker-input'
												data-target='#pesquisar'
												placeholder='Adicione + para combinar pesquisas'
												value={this.state.pesquisarAux}
												onChange={e => {
													this.setState({ ...this.state, pesquisarAux: e.target.value });
												}}
												onKeyDown={e => {
													if (e.key === 'Enter') {
														this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
													}
												}} />
											<div type='button' class='input-group-append' data-target='#pesquisar'>
												<div 
													class='input-group-text'
													onClick={e => {
														this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
													}}>
													<i class='fas fa-search'></i></div>
											</div>
										</div>
									</div>
								</div>}

								{this.state.modoTela != 'bi' &&
								<Select
									label='Período'
									minWidth={200}
									maxWidth={200}
									marginLeft={4}
									options={this.state.listaPeriodo}
									placeholder='Selecione'
									naoLimpar
									value={this.state.filtro.tipo_periodo}
									onChange={value => {
										this.setState({
											...this.state,
											filtro: {
												...this.state.filtro,
												tipo_periodo: value
											}
										});
									}} />}
									
								{this.state.modoTela != 'bi' &&
								<div style={{ display: 'flex' }}>

									<LabelAndInputMask
										label='Data Inicial' placeholder='Informe a data'
										minWidth={110}
										maxWidth={110}
										marginLeft={4}
										mask='99/99/9999'
										value={this.state.filtro.data_inicial}
										onChange={(data) => {
											this.setState({
												...this.state,
												filtro: {
													...this.state.filtro,
													data_inicial: data.target.value
												}
											});
										}} />

									<LabelAndInputMask
										label='Data Final' placeholder='Informe a data'
										minWidth={110}
										maxWidth={110}
										marginLeft={4}
										mask='99/99/9999'
										value={this.state.filtro.data_final}
										onChange={(data) => {
											this.setState({
												...this.state,
												filtro: {
													...this.state.filtro,
													data_final: data.target.value
												}
											});
										}} />
									
								</div>}
							
								<div  style={{ display: 'flex', marginTop: 8, marginLeft: 10, flexWrap: 'wrap'  }}>
									
									{this.state.modoTela != 'bi' &&
									<div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', width: 130, height: 98, marginTop: -18 }}>

										<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Nacionais'>
											{/*<i class='fas fa-home' style={{ marginRight: 4 }}></i>*/}

											<img src={iconeBandeira} style={{ marginRight: 4, width: 18, height: 18 }}></img>
											
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch1'
													checked={this.state.filtro.nacional}
													disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.nacional}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, nacional: !this.state.filtro.nacional }})} />
												<label class="custom-control-label" for="customSwitch1"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center' }} title='Internacionais'>
											<i class='fas fa-globe-americas' style={{ marginRight: 4, width: 18 }}></i>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch2'
													checked={this.state.filtro.internacional}
													disabled={!this.props.usuarioCarregado || !this.props.usuarioCarregado.internacional}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, internacional: this.props.usuarioCarregado.internacional && !this.state.filtro.internacional }})} />
												<label class="custom-control-label" for="customSwitch2"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Abertos'>
											{/* <i class='fas fa-road' style={{ marginRight: 4, width: 18 }}></i> */}

											<img src={iconeViagemAberta} style={{ marginRight: 4, width: 18, height: 20 }}></img>

											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch3'
													checked={this.state.filtro.aberto}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, aberto: !this.state.filtro.aberto }})} />
												<label class="custom-control-label" for="customSwitch3"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center' }} title='Finalizados'>
											<i class='fas fa-flag-checkered' style={{ marginRight: 4, width: 18 }}></i>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch4'
													checked={this.state.filtro.finalizado}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, finalizado: !this.state.filtro.finalizado }})} />
												<label class="custom-control-label" for="customSwitch4"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center', marginRight: 4 }} title='Apenas Ocorrências Abertas'>
											<i class='fas fa-bell' style={{ color: '#E00914', marginRight: 4, width: 18 }}></i>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch5'
													checked={this.state.filtro.ocorrencia_aberta}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, ocorrencia_aberta: !this.state.filtro.ocorrencia_aberta }})} />
												<label class="custom-control-label" for="customSwitch5"></label>
											</div>
										</div>

										<div style={{ display: 'flex', alignItems: 'center' }} title='Apenas Transit Time com Atraso'>
											{/* <i class='fas fa-flag-checkered' style={{ color: '#E00914', marginRight: 4, width: 18 }}></i> */}

											<img src={iconeRelogio} style={{ marginRight: 8, width: 18, height: 18 }}></img>

											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch6'
													checked={this.state.filtro.transit_time_atraso}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, transit_time_atraso: !this.state.filtro.transit_time_atraso }})} />
												<label class="custom-control-label" for="customSwitch6"></label>
											</div>
										</div>
										{!acessoCliente &&
										<div style={{ display: 'flex', alignItems: 'center', marginRight: 4 }} title='Classificada'>
											<i class='fas fa-exclamation-triangle' style={{ color: '#000000', marginRight: 4, width: 18 }}></i>
											<div class="custom-control custom-switch">
												<input type="checkbox" class='custom-control-input' id='customSwitch7'
													checked={this.state.filtro.classificada}
													onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, classificada: !this.state.filtro.classificada }})} />
												<label class="custom-control-label" for="customSwitch7"></label>
											</div>
										</div>}

									</div>}
									
									<div style={{ width: 320 }}>

										{this.state.modoTela != 'bi' &&
										<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
											onClick={() => {
												this.props.getLista(this.state.filtro);
											}}>
											<i class='fas fa-sync-alt'></i> Atualizar
										</a>}

										{this.state.modoTela != 'bi' &&										
										<ExcelFile
											filename={'Track 2.0'}
											element={
												<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
													<i class='fas fa-file-excel'></i> Exportar
												</a>
											}>
											{this.montarExcel(listaFiltrada, acessoCliente, indicadores)}													
										</ExcelFile>}

										{/*<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
											onClick={() => {
												this.setState({
													...this.state,
													modoTela: this.state.modoTela == 'lista' ? 'mapa' : (this.state.modoTela == 'mapa' ? 'bi' : 'lista')
												});
											}}>
											<i class={this.state.modoTela == 'lista' ? 'fas fa-map-marked-alt' : this.state.modoTela == 'mapa' ? 'far fa-chart-bar' : 'fas fa-table'}></i> 
												{this.state.modoTela == 'lista' ? 'Mapa' : this.state.modoTela == 'mapa' ? 'BI' : 'Lista'}
										</a>*/}
										<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
											onClick={() => {
												this.setState({
													...this.state,
													modoTela: this.state.modoTela == 'lista' ? 'mapa' : 'lista'
												});
											}}>
											<i class={this.state.modoTela == 'lista' ? 'fas fa-map-marked-alt' : 'fas fa-table'}></i> 
												{this.state.modoTela == 'lista' ? 'Mapa' : 'Lista'}
										</a>

										{(this.props.usuarioCarregado.contatos || []).length > 0 &&
										<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}
											onClick={() => {
												this.setState({ ...this.state, contatosModal: true })
											}}>
											<i class='fab fa-whatsapp'></i> Contatos
										</a>}
									</div>
								</div>

							</Row>
						</Grid>
					</Row>
					{this.state.modoTela == 'mapa' ? (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ marginTop: -4, marginBottom: 0, marginRight: 24 }}>Total de Solicitações: <b>{listaFiltrada.length}</b></p>

							<p style={{ marginTop: -4, marginBottom: 0, marginRight: 12 }}>Legenda: </p>

							<img src={iconeCaminhaoVermelho} style={{ marginTop: -4, marginRight: 4, width: 18, height: 18 }}></img>

							<p style={{ marginTop: -4, marginBottom: 0, marginRight: 12 }}>Atrasado</p>
							
							<img src={iconeCaminhaoVerde} style={{ marginTop: -4, marginRight: 4, width: 18, height: 18 }}></img>

							<p style={{ marginTop: -4, marginBottom: 0 }}>No Prazo</p>
						</div>
					) : (this.state.modoTela == 'lista' ? (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<p style={{ marginTop: -4, marginBottom: 0, marginRight: 24 }}>Total de Solicitações: <b>{listaFiltrada.length}</b></p>
						</div>
					) : null)}

				</ContentCardHeader>
				<ContentCardBody paddingTop={0}>
					{this.state.modoTela == 'lista' &&
					<div 
						style={{ 
							backgroundColor: '#1F3066',
							marginLeft: -8,
							marginRight: -8,
							marginBottom: -8,
							paddingTop: 1,
							paddingLeft: 8,
							paddingRight: 8,
							paddingBottom: 8,
							height: window.innerWidth < 768 ? '100%' : (
								window.innerWidth < 1093 ? `${window.innerHeight - 340}px`
								: (
									window.innerWidth < 1799 
									? `${window.innerHeight - 260}px`
									: (
										window.innerWidth < 4000 
										? `${window.innerHeight - 180}px`
										: `${window.innerHeight - 190}px`
									)
								)
							)
						}}>
					<Table responsive border striped alturaFixa 
							maxHeight={window.innerWidth < 768 ? '100%' : window.innerWidth < 768 ? '100%' : (
								window.innerWidth < 1093 ? `${window.innerHeight - 340}px`
								: (
									window.innerWidth < 1799 
									? `${window.innerHeight - 260}px`
									: (
										window.innerWidth < 4000 
										? `${window.innerHeight - 180}px`
										: `${window.innerHeight - 190}px`
									)
								)
							)}>
						<THead>

							<Tr backgroundColor={'#1F3066'} color={'#ffffff'}>
								<Th paddingTop={2} paddingBottom={2} alignCenter rowspan={2} colsPan={2} sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'}>
									<a href='#'
										title={!this.state.resumido ? 'Mostrar as informações resumidas' : 'Mostrar as informações completas com quebra de linha'}
										style={{ color: '#ffffff', fontSize: 18 }}
										onClick={(event) => {
											event.preventDefault();
											this.setState({
												...this.state,
												resumido: !this.state.resumido
											});
										}} >
										<i class={`${this.state.resumido ? 'fas fa-expand' : 'fas fa-compress'}`} />
									</a>
								</Th>
								<Th paddingTop={2} paddingBottom={2} rowspan={2} minWidth={120} onClick={() => this.setState({ ...this.state, ordenacao: { transit_time: this.getNovaOrdenacao(this.state.ordenacao.transit_time) }})} sticky width={10} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Transit Time {this.state.ordenacao.transit_time == 1 ? <i class="fas fa-sort-amount-down-alt" /> : (this.state.ordenacao.transit_time == 2 ? <i class="fas fa-sort-amount-up" /> : null)}</Th>
								
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { solicitacao: this.getNovaOrdenacao(this.state.ordenacao.solicitacao) }})} 
									sticky minWidth={60} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter title='Solicitação'>Sol. {this.getIconeOrdenacao(this.state.ordenacao.solicitacao)}</Th>

								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { shipping: this.getNovaOrdenacao(this.state.ordenacao.shipping) }})} 
									sticky minWidth={60} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter title='Shipping'>SC {this.getIconeOrdenacao(this.state.ordenacao.shipping)}</Th>	
								
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { observacao: this.getNovaOrdenacao(this.state.ordenacao.observacao) }})} 
									sticky minWidth={250} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Referência {this.getIconeOrdenacao(this.state.ordenacao.observacao)}</Th>
									
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { fatura: this.getNovaOrdenacao(this.state.ordenacao.fatura) }})} 
									sticky minWidth={250} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Fatura/Nota Fiscal {this.getIconeOrdenacao(this.state.ordenacao.fatura)}</Th>

								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { documento: this.getNovaOrdenacao(this.state.ordenacao.documento) }})} 
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Documento {this.getIconeOrdenacao(this.state.ordenacao.documento)}</Th>

								{!acessoCliente &&
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { classificada: this.getNovaOrdenacao(this.state.ordenacao.classificada) }})} 
									sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter title='Classificada'>Class. {this.getIconeOrdenacao(this.state.ordenacao.classificada)}</Th>	}

								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { cavalo: this.getNovaOrdenacao(this.state.ordenacao.cavalo) }})} 
									sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Cavalo {this.getIconeOrdenacao(this.state.ordenacao.cavalo)}</Th>

								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { carreta: this.getNovaOrdenacao(this.state.ordenacao.carreta) }})} 
									sticky minWidth={80} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Carreta {this.getIconeOrdenacao(this.state.ordenacao.carreta)}</Th>

								{!acessoCliente &&
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { cliente: this.getNovaOrdenacao(this.state.ordenacao.cliente) }})} 
									sticky minWidth={250} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Cliente {this.getIconeOrdenacao(this.state.ordenacao.cliente)}</Th>}
								{acessoCliente &&
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { remetente: this.getNovaOrdenacao(this.state.ordenacao.remetente) }})} 
									sticky minWidth={250} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Remetente {this.getIconeOrdenacao(this.state.ordenacao.remetente)}</Th>}
								{acessoCliente &&
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { destinatario: this.getNovaOrdenacao(this.state.ordenacao.destinatario) }})} 
									sticky minWidth={250} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Destinatário {this.getIconeOrdenacao(this.state.ordenacao.destinatario)}</Th>}
							
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { rota: this.getNovaOrdenacao(this.state.ordenacao.rota) }})} 
									sticky minWidth={200} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Rota {this.getIconeOrdenacao(this.state.ordenacao.rota)}</Th>

								{!acessoCliente &&
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { rumo: this.getNovaOrdenacao(this.state.ordenacao.rumo) }})} 
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Rumo {this.getIconeOrdenacao(this.state.ordenacao.rumo)}</Th>}
									
								{this.state.filtro.internacional &&
								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { fronteira: this.getNovaOrdenacao(this.state.ordenacao.fronteira) }})} 
									sticky minWidth={250} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Fronteira {this.getIconeOrdenacao(this.state.ordenacao.fronteira)}</Th>}
									
								<Th paddingTop={1} paddingBottom={1} colspan={3} sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Carregamento</Th>

								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={1} colspan={2} sticky width={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Aduana Origem</Th>}

								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={1} colspan={this.state.fronteiraResumida ? 3 : 8} sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>
									Fronteira 
									<a href='#'
										title={this.state.fronteiraResumida ? 'Mostrar Fronteira Completa' : 'Mostrar Fronteira Resumida'}
										style={{ color: '#ffffff', marginLeft: 8, fontSize: 16 }}
										onClick={(event) => {
											event.preventDefault();
											this.setState({
												...this.state,
												fronteiraResumida: !this.state.fronteiraResumida
											});
										}} >
										<i class={`${this.state.fronteiraResumida ? 'far fa-plus-square' : 'far fa-minus-square'}`} />
									</a></Th>}

								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={1} colspan={3} sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Aduana Destino</Th>}

								<Th paddingTop={1} paddingBottom={1} colspan={3} sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Descarga</Th>

								{indicadores &&
								<Th paddingTop={1} paddingBottom={1} colspan={this.state.filtro.internacional ? 4 : 2} sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Janela</Th>}
									
								{indicadores &&
								<Th paddingTop={1} paddingBottom={1} colspan={this.state.filtro.internacional ? 4 : 2} sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Indicador de Permanência</Th>}

								<Th paddingTop={2} paddingBottom={2} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { lead_time: this.getNovaOrdenacao(this.state.ordenacao.lead_time) }})} 
									title='Lead Time'
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Lead Time {this.getIconeOrdenacao(this.state.ordenacao.lead_time)}</Th>
							</Tr>

							<Tr backgroundColor={'#ffffff'} color={'#ffffff'} sticky top={25}>
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { previsao_carga: this.getNovaOrdenacao(this.state.ordenacao.previsao_carga) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Previsão {this.getIconeOrdenacao(this.state.ordenacao.previsao_carga)}</Th>

								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { inicio_carga: this.getNovaOrdenacao(this.state.ordenacao.inicio_carga) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Chegada {this.getIconeOrdenacao(this.state.ordenacao.inicio_carga)}</Th>
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { termino_carga: this.getNovaOrdenacao(this.state.ordenacao.termino_carga) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Saída {this.getIconeOrdenacao(this.state.ordenacao.termino_carga)}</Th>

								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_origem: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_origem) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Ingresso {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_origem)}</Th>}
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_origem: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_origem) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Liberação {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_origem)}</Th>}
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { previsao_fronteira: this.getNovaOrdenacao(this.state.ordenacao.previsao_fronteira) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Previsão {this.getIconeOrdenacao(this.state.ordenacao.previsao_fronteira)}</Th>}
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { entrada_fronteira: this.getNovaOrdenacao(this.state.ordenacao.entrada_fronteira) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Chegada {this.getIconeOrdenacao(this.state.ordenacao.entrada_fronteira)}</Th>}
								
								{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { autorizacao_ingresso_fronteira: this.getNovaOrdenacao(this.state.ordenacao.autorizacao_ingresso_fronteira) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Aut. Ingresso {this.getIconeOrdenacao(this.state.ordenacao.autorizacao_ingresso_fronteira)}</Th>}
								{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_front_org: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_front_org) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Ingresso Ad. Orig. {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_front_org)}</Th>}
								{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_front_org: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_front_org) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Liberação Ad. Orig. {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_front_org)}</Th>}
								{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_front_dest: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_front_dest) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Ingresso Ad. Dest. {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_front_dest)}</Th>}
								{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_front_dest: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_front_dest) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Liberação Ad. Dest. {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_front_dest)}</Th>}
																
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_fronteira: this.getNovaOrdenacao(this.state.ordenacao.liberacao_fronteira) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Saída {this.getIconeOrdenacao(this.state.ordenacao.liberacao_fronteira)}</Th>}
								
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { previsao_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.previsao_aduana_destino) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Previsão {this.getIconeOrdenacao(this.state.ordenacao.previsao_aduana_destino)}</Th>}
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { entrada_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.entrada_aduana_destino) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Ingresso {this.getIconeOrdenacao(this.state.ordenacao.entrada_aduana_destino)}</Th>}
								{this.state.filtro.internacional &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { liberacao_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.liberacao_aduana_destino) }})}
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Liberação {this.getIconeOrdenacao(this.state.ordenacao.liberacao_aduana_destino)}</Th>}
									
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { previsao_descarga: this.getNovaOrdenacao(this.state.ordenacao.previsao_descarga) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Previsão {this.getIconeOrdenacao(this.state.ordenacao.previsao_descarga)}</Th>

								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { inicio_descarga: this.getNovaOrdenacao(this.state.ordenacao.inicio_descarga) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Chegada {this.getIconeOrdenacao(this.state.ordenacao.inicio_descarga)}</Th>
								
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { termino_descarga: this.getNovaOrdenacao(this.state.ordenacao.termino_descarga) }})} 
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Saída {this.getIconeOrdenacao(this.state.ordenacao.termino_descarga)}</Th>

								{indicadores &&
								<Th paddingTop={1} paddingBottom={2} top={26} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_carga: this.getNovaOrdenacao(this.state.ordenacao.indicador_carga) }})} 
									title='Janela Carga'
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Carga {this.getIconeOrdenacao(this.state.ordenacao.indicador_carga)}</Th>}

								{(indicadores && this.state.filtro.internacional) &&
								<Th paddingTop={1} paddingBottom={2} top={26} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_fronteira: this.getNovaOrdenacao(this.state.ordenacao.indicador_fronteira) }})} 
									title='Janela Fronteira'
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Fronteira {this.getIconeOrdenacao(this.state.ordenacao.indicador_fronteira)}</Th>}

								{(indicadores && this.state.filtro.internacional) &&
								<Th paddingTop={1} paddingBottom={2} top={26} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.indicador_aduana_destino) }})} 
									title='Janela Aduana Destino'
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Ad. Dest. {this.getIconeOrdenacao(this.state.ordenacao.indicador_aduana_destino)}</Th>}

								{indicadores &&
								<Th paddingTop={1} paddingBottom={2} top={26} rowspan={2} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_descarga: this.getNovaOrdenacao(this.state.ordenacao.indicador_descarga) }})} 
									title='Janela Descarga'
									sticky minWidth={100} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Descarga {this.getIconeOrdenacao(this.state.ordenacao.indicador_descarga)}</Th>}

								{indicadores &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_permanencia_carga: this.getNovaOrdenacao(this.state.ordenacao.indicador_permanencia_carga) }})} 
									title='Carga'
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Carga {this.getIconeOrdenacao(this.state.ordenacao.indicador_permanencia_carga)}</Th>}

								{(indicadores && this.state.filtro.internacional) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_permanencia_fronteira: this.getNovaOrdenacao(this.state.ordenacao.indicador_permanencia_fronteira) }})} 
									title='Fronteira'
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Fronteira {this.getIconeOrdenacao(this.state.ordenacao.indicador_permanencia_fronteira)}</Th>}

								{(indicadores && this.state.filtro.internacional) &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_permanencia_aduana_destino: this.getNovaOrdenacao(this.state.ordenacao.indicador_permanencia_aduana_destino) }})} 
									title='Aduana Destino'
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Ad. Dest. {this.getIconeOrdenacao(this.state.ordenacao.indicador_permanencia_aduana_destino)}</Th>}

								{indicadores &&
								<Th paddingTop={1} paddingBottom={2} top={26} onClick={() => this.setState({ ...this.state, ordenacao: { indicador_permanencia_descarga: this.getNovaOrdenacao(this.state.ordenacao.indicador_permanencia_descarga) }})} 
									title='Descarga'
									sticky minWidth={150} backgroundColor={'#1F3066'} color={'#ffffff'} alignCenter>Descarga {this.getIconeOrdenacao(this.state.ordenacao.indicador_permanencia_descarga)}</Th>}
									
							</Tr>
						</THead>
						<TBody>
							{listaFiltrada.map((item, i) => {
								
								let colorCargaDescarga = null;
								let colorAduanaOrigem = item.aduana_origem ? null : (this.state.linhaSelecionada == item.solicitacao_carga_id ? '#bcc7eb' : '#bfc7c9');
								let colorFronteira = item.fronteira ? null : (this.state.linhaSelecionada == item.solicitacao_carga_id ? '#bcc7eb' : '#bfc7c9');
								let colorAduanaDestino = item.aduana_destino ? null : (this.state.linhaSelecionada == item.solicitacao_carga_id ? '#bcc7eb' : '#bfc7c9');

								let corTransitTime = 'success';
								if (item.transit_time < 100 && item.datahora_previsao_real) {
									if (!item.data_inicio_carga) {
										if (item.data_previsao_carga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_carga).getTime()) {
											corTransitTime = 'danger';
										}
									} else if (item.fronteira && item.data_termino_carga && !item.data_entrada_fronteira) {
										if (item.data_prev_fronteira && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_prev_fronteira).getTime()) {
											corTransitTime = 'danger';
										}
									} else if (((!item.fronteira && item.data_termino_carga) || (item.fronteira && item.data_liberacao_fronteira)) && !item.data_inicio_descarga) {
										if (item.data_previsao_descarga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_descarga).getTime()) {
											corTransitTime = 'danger';
										}
									}
								}
			
								return (
									<>
										<Tr key={`${item.shipping_code_id} ${item.solicitacao_carga_id}`} 
											backgroundColor={this.state.linhaSelecionada == item.solicitacao_carga_id ? '#bcc7eb' : (i % 2 == 0 ? '#ffffff' : '#f5f5f5')}
											>
											<Td minWidth={38} color={item.ocorrencias_abertas > 0 ? '#E00914' : '#000'} alignCenter>
												{(item.ocorrencias_abertas > 0 || item.ocorrencias_finalizadas > 0) &&
												<ButtonTable
													type={item.ocorrencias_abertas > 0 ? 'danger' : 'secondary'}
													icon={'fas fa-bell'}
													visible={true}
													small
													title={'Ver Ocorrências'}
													event={() => {

														this.props.getListaOcorrencias(item.shipping_code_id);

														this.setState({
															...this.state,
															registroModalOcorrencia: { ...item }
														});
													}} />}
											</Td>
											<Td minWidth={38} color={'#000'} alignCenter>
												<ButtonTable
													type={'primary'}
													icon={'fas fa-eye'}
													visible={true}
													small
													title={'Ver detalhes'}
													event={() => {
														this.setState({
															...this.state,
															registroModal: { ...item }
														});
													}} />
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} color={'#000'} alignCenter small title='Transit Time'>
												<div style={{ }}>
													<div class='progress-group' style={{ width: 100, paddingTop: 4 }} >
														<div class='progress progress-sm' style={{ height: 20, marginBottom: -17, backgroundColor: '#949494', borderRadius: 4 }}>
															<div class={`progress-bar bg-${corTransitTime}`} style={{ width: `${item.transit_time}%` }}></div>
														</div>
														<div style={{ color: '#fff', fontWeight: 'bold' }}>{item.transit_time}%</div>
													</div>
												</div>
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.solicitacao_carga_id}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.shipping_code_id}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.observacao}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.fatura || ''}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} color={'#000'} alignCenter small>{item.documento}</Td>
											{!acessoCliente &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={50} color={'#000'} alignCenter small>
												{item.classificada &&
												<i class='fas fa-exclamation-triangle' title='Classificada'></i>}
											</Td>}
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.cavalo}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10} color={'#000'} alignCenter small>{item.carreta}</Td>
											
											{!acessoCliente &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.negociador}</Td>}
											{acessoCliente &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.nome_remetente_sol}</Td>}
											{acessoCliente &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.nome_destinatario_sol}</Td>}

											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={200} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.rota}</Td>
											{!acessoCliente && 
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={100} color={'#000'} alignCenter small>{item.rumo}</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={250} color={'#000'} alignCenter small noWrap={this.state.resumido}>{item.nome_fronteira}</Td>}
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small color={'#000'}>{DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga)}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small color={'#000'}>{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_carga)}</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small color={'#000'}>{DateFormat.formatarDataHoraSqlParaTela(item.data_termino_carga)}</Td>
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_entrada_aduana_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_entrada_aduana_origem ? '#de0f00' : colorAduanaOrigem}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_origem)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_saida_aduana_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_origem ? '#de0f00' : colorAduanaOrigem}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_origem)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_previsao_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_previsao_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_prev_fronteira)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_chegada_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_chegada_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_fronteira)}
											</Td>}
											{(this.state.filtro.internacional && !this.state.fronteiraResumida) && 
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_autorizacao_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_autorizacao_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_autorizacao_ingresso_fronteira)}
											</Td>}
											{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
											<Td minWidth={150} alignCenter small 
												color={item.erro_entrada_aduana_fronteira_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_entrada_aduana_fronteira_origem ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_org)}
											</Td>}
											{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_saida_aduana_fronteira_origem ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_fronteira_origem ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_org)}
											</Td>}
											{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_entrada_aduana_fronteira_destino ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_entrada_aduana_fronteira_destino ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_dest)}
											</Td>}
											{(this.state.filtro.internacional && !this.state.fronteiraResumida) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_saida_aduana_fronteira_destino ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_fronteira_destino ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_dest)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_saida_fronteira ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_fronteira ? '#de0f00' : colorFronteira}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_fronteira)}
											</Td>}

											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_entrada_aduana_destino ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_entrada_aduana_destino ? '#de0f00' : colorAduanaDestino}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_prev_aduana_destino)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_entrada_aduana_destino ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_entrada_aduana_destino ? '#de0f00' : colorAduanaDestino}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_destino)}
											</Td>}
											{this.state.filtro.internacional &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_saida_aduana_destino ? '#ffffff' : '#000000'} 
												backgroundColor={item.erro_saida_aduana_destino ? '#de0f00' : colorAduanaDestino}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_destino)}
											</Td>}
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_previsao_descarga ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_previsao_descarga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga)}
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_chegada_descarga ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_chegada_descarga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_descarga)}
											</Td>
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={150} alignCenter small 
												color={item.erro_saida_descarga ? '#ffffff' : '#000000'}
												backgroundColor={item.erro_saida_descarga ? '#de0f00' : colorCargaDescarga}>
												{DateFormat.formatarDataHoraSqlParaTela(item.data_termino_descarga)}
											</Td>

											{indicadores &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												style={{ marginLeft: 4 }}
												color={!item.data_previsao_carga ? '#bfbfbf' : (item.chegada_carga_dias + item.chegada_carga_horas + item.chegada_carga_minutos) > 0 ? '#E00914' : '#00cf1f' } 
												title={'Status'} 
												alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18, marginLeft: 6 }}></i>
											</Td>}

											{(indicadores && this.state.filtro.internacional) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												style={{ marginLeft: 4 }}
												color={!item.data_prev_fronteira ? '#bfbfbf' : (item.chegada_fronteira_dias + item.chegada_fronteira_horas + item.chegada_fronteira_minutos) > 0 ? '#E00914' : '#00cf1f' } 
												title={'Status'} 
												alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18, marginLeft: 6 }}></i>
											</Td>}
											{(indicadores && this.state.filtro.internacional) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												style={{ marginLeft: 4 }}
												color={!item.data_prev_aduana_destino ? '#bfbfbf' : (item.chegada_aduana_destino_dias + item.chegada_aduana_destino_horas + item.chegada_aduana_destino_minutos) > 0 ? '#E00914' : '#00cf1f' } 
												title={'Status'} 
												alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18, marginLeft: 6 }}></i>
											</Td>}
											{indicadores &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={10}
												style={{ marginLeft: 4 }}
												color={!item.data_previsao_descarga ? '#bfbfbf' : (item.chegada_descarga_dias + item.chegada_descarga_horas + item.chegada_descarga_minutos) > 0 ? '#E00914' : '#00cf1f' } 
												title={'Status'} 
												alignCenter small>
												<i class='fas fa-circle' style={{ fontSize: 18, marginLeft: 6 }}></i>
											</Td>}
										
											{indicadores &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90}
												style={{ marginLeft: 4 }}
												color={'#000'} alignCenter small
												title={`${item.janela_carga_dias > 0 ? `${item.janela_carga_dias}d` : ''} ${item.janela_carga_horas > 0 ? `${item.janela_carga_horas}h` : ''} ${item.janela_carga_minutos > 0 ? `${item.janela_carga_minutos}min` : ''}`}>
												{/*{item.janela_carga_dias > 0 ? `${item.janela_carga_dias}d` : ''} {item.janela_carga_horas > 0 ? `${item.janela_carga_horas}h` : ''} {item.janela_carga_minutos > 0 ? `${item.janela_carga_minutos}min` : ''}*/}
												{FormatUtils.formatarValorTela(
													item.janela_carga_dias + (item.janela_carga_horas / 24) + (item.janela_carga_minutos / 60 / 24) > 0 
														? item.janela_carga_dias + (item.janela_carga_horas / 24) + (item.janela_carga_minutos / 60 / 24) 
														: 0
												, 3)} {item.janela_carga_dias + (item.janela_carga_horas / 24) + (item.janela_carga_minutos / 60 / 24) == 1 ? 'dia' : 'dias'}
											</Td>}

											{(indicadores && this.state.filtro.internacional) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90}
												style={{ marginLeft: 4 }}
												color={'#000'} alignCenter small
												title={`${item.janela_fronteira_dias > 0 ? `${item.janela_fronteira_dias}d` : ''} ${item.janela_fronteira_horas > 0 ? `${item.janela_fronteira_horas}h` : ''} ${item.janela_fronteira_minutos > 0 ? `${item.janela_fronteira_minutos}min` : ''}`}>
												{/*{item.janela_fronteira_dias > 0 ? `${item.janela_fronteira_dias}d` : ''} {item.janela_fronteira_horas > 0 ? `${item.janela_fronteira_horas}h` : ''} {item.janela_fronteira_minutos > 0 ? `${item.janela_fronteira_minutos}min` : ''}*/}
												{FormatUtils.formatarValorTela(
													item.janela_fronteira_dias + (item.janela_fronteira_horas / 24) + (item.janela_fronteira_minutos / 60 / 24) > 0 
													? item.janela_fronteira_dias + (item.janela_fronteira_horas / 24) + (item.janela_fronteira_minutos / 60 / 24) 
													: 0
												, 3)} {item.janela_fronteira_dias + (item.janela_fronteira_horas / 24) + (item.janela_fronteira_minutos / 60 / 24) == 1 ? 'dia' : 'dias'}
											</Td>}

											{(indicadores && this.state.filtro.internacional) &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90}
												style={{ marginLeft: 4 }}
												color={'#000'} alignCenter small
												title={`${item.janela_aduana_destino_dias > 0 ? `${item.janela_aduana_destino_dias}d` : ''} ${item.janela_aduana_destino_horas > 0 ? `${item.janela_aduana_destino_horas}h` : ''} ${item.janela_aduana_destino_minutos > 0 ? `${item.janela_aduana_destino_minutos}min` : ''}`}>
												{/*{item.janela_aduana_destino_dias > 0 ? `${item.janela_aduana_destino_dias}d` : ''} {item.janela_aduana_destino_horas > 0 ? `${item.janela_aduana_destino_horas}h` : ''} {item.janela_aduana_destino_minutos > 0 ? `${item.janela_aduana_destino_minutos}min` : ''}*/}
												{FormatUtils.formatarValorTela(
													item.janela_aduana_destino_dias + (item.janela_aduana_destino_horas / 24) + (item.janela_aduana_destino_minutos / 60 / 24) > 0 
													? item.janela_aduana_destino_dias + (item.janela_aduana_destino_horas / 24) + (item.janela_aduana_destino_minutos / 60 / 24) 
													: 0
												, 3)} {item.janela_aduana_destino_dias + (item.janela_aduana_destino_horas / 24) + (item.janela_aduana_destino_minutos / 60 / 24) == 1 ? 'dia' : 'dias'}
											</Td>}
											
											{indicadores &&
											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90}
												style={{ marginLeft: 4 }}
												color={'#000'} alignCenter small
												title={`${item.janela_descarga_dias > 0 ? `${item.janela_descarga_dias}d` : ''} ${item.janela_descarga_horas > 0 ? `${item.janela_descarga_horas}h` : ''} ${item.janela_descarga_minutos > 0 ? `${item.janela_descarga_minutos}min` : ''}`}>
												{/*{item.janela_descarga_dias > 0 ? `${item.janela_descarga_dias}d` : ''} {item.janela_descarga_horas > 0 ? `${item.janela_descarga_horas}h` : ''} {item.janela_descarga_minutos > 0 ? `${item.janela_descarga_minutos}min` : ''}*/}
												{FormatUtils.formatarValorTela(
													item.janela_descarga_dias + (item.janela_descarga_horas / 24) + (item.janela_descarga_minutos / 60 / 24) > 0 
													? item.janela_descarga_dias + (item.janela_descarga_horas / 24)  + (item.janela_descarga_minutos / 60 / 24)
													: 0
												, 3)} {item.janela_descarga_dias + (item.janela_descarga_horas / 24) + (item.janela_descarga_minutos / 60 / 24) == 1 ? 'dia' : 'dias'}
											</Td>}

											<Td onClick={() => setLinhaSelecionada(item.solicitacao_carga_id)} minWidth={90}
												style={{ marginLeft: 4 }}
												color={'#000'} alignCenter small
												title={`${item.lead_time_dias > 0 ? `${item.lead_time_dias}d` : ''} ${item.lead_time_horas > 0 ? `${item.lead_time_horas}h` : ''} ${item.lead_time_minutos > 0 ? `${item.lead_time_minutos}min` : ''}`}>
												{/*{item.lead_time_dias > 0 ? `${item.lead_time_dias}d` : ''} {item.lead_time_horas > 0 ? `${item.lead_time_horas}h` : ''} {item.lead_time_minutos > 0 ? `${item.lead_time_minutos}min` : ''}*/}
												{FormatUtils.formatarValorTela(
													item.lead_time_dias + (item.lead_time_horas / 24) + (item.lead_time_minutos / 60 / 24) > 0 
													? item.lead_time_dias + (item.lead_time_horas / 24) + (item.lead_time_minutos / 60 / 24) 
													: 0
												, 3)} {item.lead_time_dias + (item.lead_time_horas / 24) + (item.lead_time_minutos / 60 / 24) == 1 ? 'dia' : 'dias'}
											</Td>
										</Tr>
									</>
								)
							})}
						</TBody>
					</Table>
					</div>}
					
					{this.state.modoTela == 'mapa' ? this.modoMapa(listaFiltrada) : null}

					{this.state.modoTela == 'bi' ? this.modoBI() : null}

				</ContentCardBody>
			</ContentCard>
		);
	}

	modoMapa(lista) {

		return (
			<div 
				style={{ 
					backgroundColor: '#1F3066',
					marginLeft: -8,
					marginRight: -8,
					marginBottom: -8,
					paddingTop: 1,
					paddingLeft: 8,
					paddingRight: 8,
					paddingBottom: 8
				}}>
				<div 
					style={{ height: window.innerWidth < 768 ? window.innerHeight - 60 : (window.innerWidth < 1799 ? '72vh' : `${(window.innerHeight - 60) * 0.0926}vh`) }} >
					
					<MapLeaflet
						zIndex={0}
						coordenadas={lista.filter(item => item.cavalo && item.veiculo_latitude && item.veiculo_longitude && !item.data_termino_descarga).map(item => {

							let corTransitTime = 'success';
							if (item.transit_time < 100 && item.datahora_previsao_real) {
								if (!item.data_inicio_carga) {
									if (item.data_previsao_carga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_carga).getTime()) {
										corTransitTime = 'danger';
									}
								} else if (item.fronteira && item.data_termino_carga && !item.data_entrada_fronteira) {
									if (item.data_prev_fronteira && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_prev_fronteira).getTime()) {
										corTransitTime = 'danger';
									}
								} else if (((!item.fronteira && item.data_termino_carga) || (item.fronteira && item.data_liberacao_fronteira)) && !item.data_inicio_descarga) {
									if (item.data_previsao_descarga && new Date(item.datahora_previsao_real).getTime() > new Date(item.data_previsao_descarga).getTime()) {
										corTransitTime = 'danger';
									}
								}
							}
							
							let previsaoCarga = null;
							let previsaoFronteira = null;
							let previsaoDescarga = null;
							if (item.rumo != 'NAC') {
								if (!item.data_inicio_carga) {
									previsaoCarga = DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga);
								} else if (item.data_termino_carga && !item.data_entrada_fronteira) {
									previsaoFronteira = DateFormat.formatarDataHoraSqlParaTela(item.data_prev_fronteira);
								} else if (item.data_liberacao_fronteira && !item.data_inicio_descarga) {
									previsaoDescarga = DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga);
								}
							} else {
								if (!item.data_inicio_carga) {
									previsaoCarga = DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga);
								} else if (item.data_termino_carga && !item.data_inicio_descarga) {
									previsaoDescarga = DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga);
								}
							}

							return {
								id: item.solicitacao_carga_id,
								titulo: item.cavalo,
								body: (
									<div>
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Documento: {item.documento}</p>
										{item.origem &&
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Origem: {(item.origem || '')}</p>}
										{item.origem &&
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Destino: {(item.destino || '')}</p>}
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Cliente: {(item.negociador || '')}</p>
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Referência: {(item.observacao || '')}</p>
										{previsaoCarga &&
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Prev. Carga: {previsaoCarga}</p>}
										{previsaoFronteira &&
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Prev. Fronteira: {previsaoFronteira}</p>}
										{previsaoDescarga &&
										<p style={{ paddingBottom: 0, marginBottom: 0 }}>Prev. Descarga: {previsaoDescarga}</p>}
									</div>
								),
								latitude: item.veiculo_latitude,
								longitude: item.veiculo_longitude,
								direcao: item.rumo == 'RS' ? 'S' : 'N',
								status: corTransitTime
							}
						})}
						zoom={4}
						latitude={'-28.381875'}
						longitude={'-53.937355'}
						onChange={(coordenadas) => {
							
						}} />
				</div>
			</div>
		);
	}

	modoBI() {

		return (
			<iframe title="KPI - SC JOHNSON BR" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=1c35a469-7d5e-4dc5-8775-f9a7329519e5&autoAuth=true&ctid=370235b0-bf1e-47ec-ab6e-3b6fa12c7853" frameborder="0" allowFullScreen="true"></iframe>
		)

		return (
			<PowerBIEmbed
				embedConfig={{
					type: 'dashboard',   // Embed type
					id: '1c35a469-7d5e-4dc5-8775-f9a7329519', // Report ID
					embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=1c35a469-7d5e-4dc5-8775-f9a7329519e5&autoAuth=true&ctid=370235b0-bf1e-47ec-ab6e-3b6fa12c7853',
					accessToken: '370235b0-bf1e-47ec-ab6e-3b6fa12c7853',
					tokenType: models.TokenType.Embed,
					settings: {
						filterPaneEnabled: false,
						navContentPaneEnabled: false
					}
				}}
				eventHandlers={
					[
						['loaded', function () {console.log('Report loaded');}],
						['rendered', function () {console.log('Report rendered');}],
						['error', function (event) {console.log(event.detail);}],
						['visualClicked', () => console.log('visual clicked')],
						['pageChanged', (event) => console.log(event)],
					]
				}
				/>
		)

		return (

			<iframe
				title='Power BI'
				src={`https://app.powerbi.com/view?r=eyJrIjoiZmYzMjU0NGUtZmZiOC00MWNjLTg4OTgtNjFiZGU4NGVjOTg4IiwidCI6IjM3MDIzNWIwLWJmMWUtNDdlYy1hYjZlLTNiNmZhMTJjNzg1MyJ9`}
				style={{
					width: '100%', 
					height: window.innerHeight - 180
				}}
				frameBorder="0"
				allowFullScreen="true"
				/>
		)
	}

	montarExcel(listaFiltrada, acessoCliente, indicadores) {


		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<ExcelSheet data={listaFiltrada.map(item => ({
				...item,
				transit_time: `${item.transit_time}%`,
				data_previsao_carga: DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_carga),
				data_inicio_carga: DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_carga),
				data_termino_carga: DateFormat.formatarDataHoraSqlParaTela(item.data_termino_carga),
				data_entrada_aduana_origem: DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_origem),
				data_liberacao_aduana_origem: DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_origem),
				data_prev_fronteira: DateFormat.formatarDataHoraSqlParaTela(item.data_prev_fronteira),
				data_entrada_fronteira: DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_fronteira),
				data_autorizacao_ingresso_fronteira: DateFormat.formatarDataHoraSqlParaTela(item.data_autorizacao_ingresso_fronteira),
				dt_entrada_aduana_front_org: DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_org),
				dt_liberacao_aduana_front_org: DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_org),
				dt_entrada_aduana_front_dest: DateFormat.formatarDataHoraSqlParaTela(item.dt_entrada_aduana_front_dest),
				dt_liberacao_aduana_front_dest: DateFormat.formatarDataHoraSqlParaTela(item.dt_liberacao_aduana_front_dest),
				data_liberacao_fronteira: DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_fronteira),
				data_prev_aduana_destino: DateFormat.formatarDataHoraSqlParaTela(item.data_prev_aduana_destino),
				data_entrada_aduana_destino: DateFormat.formatarDataHoraSqlParaTela(item.data_entrada_aduana_destino),
				data_liberacao_aduana_destino: DateFormat.formatarDataHoraSqlParaTela(item.data_liberacao_aduana_destino),
				data_previsao_descarga: DateFormat.formatarDataHoraSqlParaTela(item.data_previsao_descarga),
				data_inicio_descarga: DateFormat.formatarDataHoraSqlParaTela(item.data_inicio_descarga),
				data_termino_descarga: DateFormat.formatarDataHoraSqlParaTela(item.data_termino_descarga),

				indicador_carga: !item.data_previsao_carga ? '' : ((item.chegada_carga_dias + item.chegada_carga_horas + item.chegada_carga_minutos) > 0 ? 'Atrasado' : 'Adiantado'),
				indicador_carga_descritivo: parseFloat(FormatUtils.formatarValorBanco(FormatUtils.formatarValorTela(item.janela_carga_dias + (item.janela_carga_horas / 24) + (item.janela_carga_minutos / 60 / 24) > 0 ? item.janela_carga_dias + (item.janela_carga_horas / 24) + (item.janela_carga_minutos / 60 / 24) : 0, 3), 3)),

				indicador_fronteira: !item.data_prev_fronteira ? '' : ((item.chegada_fronteira_dias + item.chegada_fronteira_horas + item.chegada_fronteira_minutos) > 0 ? 'Atrasado' : 'Adiantado'),
				indicador_fronteira_descritivo: parseFloat(FormatUtils.formatarValorBanco(FormatUtils.formatarValorTela(item.janela_fronteira_dias + (item.janela_fronteira_horas / 24) + (item.janela_fronteira_minutos / 60 / 24) > 0 ? item.janela_fronteira_dias + (item.janela_fronteira_horas / 24) + (item.janela_fronteira_minutos / 60 / 24) : 0, 3), 3)),

				indicador_aduana_destino: !item.data_prev_aduana_destino ? '' : ((item.chegada_aduana_destino_dias + item.chegada_aduana_destino_horas + item.chegada_aduana_destino_minutos) > 0 ? 'Atrasado' : 'Adiantado'),
				indicador_aduana_destino_descritivo: parseFloat(FormatUtils.formatarValorBanco(FormatUtils.formatarValorTela(item.janela_aduana_destino_dias + (item.janela_aduana_destino_horas / 24) + (item.janela_aduana_destino_minutos / 60 / 24) > 0 ? item.janela_aduana_destino_dias + (item.janela_aduana_destino_horas / 24) + (item.janela_aduana_destino_minutos / 60 / 24) : 0, 3), 3)),

				indicador_descarga: !item.data_previsao_descarga ? '' : ((item.chegada_descarga_dias + item.chegada_descarga_horas + item.chegada_descarga_minutos) > 0 ? 'Atrasado' : 'Adiantado'),
				indicador_descarga_descritivo: parseFloat(FormatUtils.formatarValorBanco(FormatUtils.formatarValorTela(item.janela_descarga_dias + (item.janela_descarga_horas / 24) + (item.janela_descarga_minutos / 60 / 24) > 0 ? item.janela_descarga_dias + (item.janela_descarga_horas / 24) + (item.janela_descarga_minutos / 60 / 24) : 0, 3), 3)),

				lead_time: parseFloat(FormatUtils.formatarValorBanco(FormatUtils.formatarValorTela(item.lead_time_dias + (item.lead_time_horas / 24) + (item.lead_time_minutos / 60 / 24) > 0 ? item.lead_time_dias + (item.lead_time_horas / 24) + (item.lead_time_minutos / 60 / 24) : 0, 3), 3)),

				tipo_ocorrencia_1: (item.ocorrencias || [])[0] ? (item.ocorrencias || [])[0].tipo_ocorrencia : '',
				responsavel_ocorrencia_1: (item.ocorrencias || [])[0] ? ((item.ocorrencias || [])[0].responsabilidade == 0 ? 'Cliente' : 'Letsara') : '',
				inicio_ocorrencia_1: (item.ocorrencias || [])[0] ? DateFormat.formatarDataHoraSqlParaTela((item.ocorrencias || [])[0].data_inicio) : '',
				fim_ocorrencia_1: (item.ocorrencias || [])[0] ? DateFormat.formatarDataHoraSqlParaTela((item.ocorrencias || [])[0].data_termino) : '',

				tipo_ocorrencia_2: (item.ocorrencias || [])[1] ? (item.ocorrencias || [])[1].tipo_ocorrencia : '',
				responsavel_ocorrencia_2: (item.ocorrencias || [])[1] ? ((item.ocorrencias || [])[1].responsabilidade == 0 ? 'Cliente' : 'Letsara') : '',
				inicio_ocorrencia_2: (item.ocorrencias || [])[1] ? DateFormat.formatarDataHoraSqlParaTela((item.ocorrencias || [])[1].data_inicio) : '',
				fim_ocorrencia_2: (item.ocorrencias || [])[1] ? DateFormat.formatarDataHoraSqlParaTela((item.ocorrencias || [])[1].data_termino) : '',

				tipo_ocorrencia_3: (item.ocorrencias || [])[2] ? (item.ocorrencias || [])[2].tipo_ocorrencia : '',
				responsavel_ocorrencia_3: (item.ocorrencias || [])[2] ? ((item.ocorrencias || [])[2].responsabilidade == 0 ? 'Cliente' : 'Letsara') : '',
				inicio_ocorrencia_3: (item.ocorrencias || [])[2] ? DateFormat.formatarDataHoraSqlParaTela((item.ocorrencias || [])[2].data_inicio) : '',
				fim_ocorrencia_3: (item.ocorrencias || [])[2] ? DateFormat.formatarDataHoraSqlParaTela((item.ocorrencias || [])[2].data_termino) : '',
				classificada: item.classificada ? 'Sim' : ''
			}))} name='Track 2.0'>
				{[
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={1} label='Transit Time' value='transit_time' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={2} label='Ship.' value='shipping_code_id' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={3} label='Sol.' value='solicitacao_carga_id' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={4} label='Referência' value='observacao' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={5} label='Fatura/Nota Fiscal' value='fatura' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={6} label='Documento' value='documento' /> },
					{ internacional: false, adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={7} label='Classificada' value='classificada' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={8} label='Cavalo' value='cavalo' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={9} label='Carreta' value='carreta' /> },
					{ internacional: false, adm: true,  cliente: false, indicador: false, componente: <ExcelColumn key={10} label='Cliente' value='negociador' /> },
					{ internacional: false, adm: false, cliente: true,  indicador: false, componente: <ExcelColumn key={11} label='Remetente' value='nome_remetente_sol' /> },
					{ internacional: false, adm: false, cliente: true,  indicador: false, componente: <ExcelColumn key={12} label='Destinatário' value='nome_destinatario_sol' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={13} label='Rota' value='rota' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={14} label='Rumo' value='rumo' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={15} label='Fronteira' value='nome_fronteira' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={16} label='Previsão Carga' value='data_previsao_carga' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={17} label='Chegada Carga' value='data_inicio_carga' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={18} label='Saída Carga' value='data_termino_carga' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={19} label='Chegada Aduana Origem' value='data_entrada_aduana_origem' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={20} label='Liberação Aduana Origem' value='data_liberacao_aduana_origem' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={21} label='Previsão Fronteira' value='data_prev_fronteira' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={22} label='Chegada Fronteira' value='data_entrada_fronteira' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={23} label='Autorização Ingresso fronteira' value='data_autorizacao_ingresso_fronteira' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={24} label='Chegada Aduana Fronteira Origem' value='dt_entrada_aduana_front_org' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={25} label='Liberação Aduana Fronteira Origem' value='dt_liberacao_aduana_front_org' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={26} label='Chegada Aduana Fronteira Destino' value='dt_entrada_aduana_front_dest' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={27} label='Liberação Aduana Fronteira Destino' value='dt_liberacao_aduana_front_dest' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={28} label='Liberação Fronteira' value='data_liberacao_fronteira' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={29} label='Previsão Aduana Destino' value='data_prev_aduana_destino' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={30} label='Chegada Aduana Destino' value='data_entrada_aduana_destino' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={31} label='Liberação Aduana Destino' value='data_liberacao_aduana_destino' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={32} label='Previsão Descarga' value='data_previsao_descarga' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={33} label='Chegada Descarga' value='data_inicio_descarga' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={34} label='Saída Descarga' value='data_termino_descarga' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={34} label='JC' value='indicador_carga' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={35} label='JF' value='indicador_fronteira' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={36} label='JAD' value='indicador_aduana_destino' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={37} label='JD' value='indicador_descarga' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={37} label='Tempo de Permanência Carga' value='indicador_carga_descritivo' type='number' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={38} label='Tempo de Permanência Fronteira' value='indicador_fronteira_descritivo' type='number' /> },
					{ internacional: true,  adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={39} label='Tempo de Permanência Aduana Destino' value='indicador_aduana_destino_descritivo' type='number' /> },
					{ internacional: false, adm: true,  cliente: true,  indicador: true,  componente: <ExcelColumn key={40} label='Tempo de Permanência Descarga' value='indicador_descarga_descritivo' type='number' /> },
					{ internacional: false,  adm: true,  cliente: true,  indicador: false, componente: <ExcelColumn key={41} label='Lead Time' value='lead_time' type='number' /> },

					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={42} label='Tipo Ocorrência 1' value='tipo_ocorrencia_1' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={43} label='Responsavel Ocorrência 1' value='responsavel_ocorrencia_1' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={44} label='Início Ocorrência 1' value='inicio_ocorrencia_1' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={45} label='Fim Ocorrência 1' value='fim_ocorrencia_1' /> },

					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={46} label='Tipo Ocorrência 2' value='tipo_ocorrencia_2' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={47} label='Responsavel Ocorrência 2' value='responsavel_ocorrencia_2' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={48} label='Início Ocorrência 2' value='inicio_ocorrencia_2' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={49} label='Fim Ocorrência 2' value='fim_ocorrencia_2' /> },

					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={50} label='Tipo Ocorrência 3' value='tipo_ocorrencia_3' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={51} label='Responsavel Ocorrência 3' value='responsavel_ocorrencia_3' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={52} label='Início Ocorrência 3' value='inicio_ocorrencia_3' /> },
					{ internacional: false,  adm: true,  cliente: false,  indicador: false, componente: <ExcelColumn key={53} label='Fim Ocorrência 3' value='fim_ocorrencia31' /> }

				].filter(item => {

					if (acessoCliente && !item.cliente) {
						return false;
					}
					if (!acessoCliente && !item.adm) {
						return false;
					}
					if (!this.state.filtro.internacional && item.internacional) {
						return false;
					}
					if (acessoCliente && !indicadores && item.indicador) {
						return false;
					}

					return true;

				}).map(item => (
					item.componente
				))}
			</ExcelSheet>
		);
	}

	controleFavoritos(idShippingCode) {
		let lista = JSON.parse(Cookies.get('shippingsFavoritos') || '[]');

		if (this.isFavorito(idShippingCode)) {
			lista = lista.filter(item => item != idShippingCode);
		} else {
			lista.push(idShippingCode);
		}

		lista = lista.filter(item => (this.props.lista || []).filter(shipping => shipping.shipping_code_id == item).length);

		this.setState({ ...this.state, listaFavoritos: lista });

		Cookies.set('shippingsFavoritos', JSON.stringify(lista), { expires: 15 });
	}

	isFavorito(idShippingCode) {
		return (this.state.listaFavoritos || []).filter(shipping => shipping == idShippingCode).length > 0;
	}
	
	modalShipping() {

		const qtdSolicitacoes = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).length;
		let mostrarColunaSolicitacao = false;
		this.props.listaPrevisoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id).map(item => {
			if ((item.solicitacoes || []).filter(sol => sol).length != qtdSolicitacoes) {
				mostrarColunaSolicitacao = true;
			}
		});

		let solicitacao = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id)[0] || {};

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Shipping Code: {this.state.registroModal.shipping_code_id} <i class={this.isFavorito(this.state.registroModal.shipping_code_id) ? 'fas fa-star' : 'far fa-star'} title={this.isFavorito(this.state.registroModal.shipping_code_id) ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={() => this.controleFavoritos(this.state.registroModal.shipping_code_id)} style={{ marginLeft: 8, cursor: 'pointer' }}></i></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroModal: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ height: window.innerHeight * 0.8, overflowY: 'scroll' }}>

							<h6>Placas</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Solicitação</Th>
										<Th alignCenter>Cavalo</Th>
										<Th alignCenter>Carreta</Th>
										<Th alignCenter>Motorista</Th>
										<Th alignCenter>Local</Th>
										<Th alignCenter>Latitude/Longitude</Th>
										<Th alignCenter>Status</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.lista.filter(item => item.solicitacao_carga_id == this.state.registroModal.solicitacao_carga_id).map(item => (
										<Tr key={item.solicitacao_carga_id}>
											<Td alignCenter small>{item.solicitacao_carga_id || ''}</Td>
											<Td alignCenter small>{item.cavalo || ''}</Td>
											<Td alignCenter small>{item.carreta || ''}</Td>
											<Td alignCenter small>{item.motorista || ''}</Td>
											<Td alignCenter small>{item.veiculo_referencia || ''}</Td>
											<Td alignCenter small>
												{(item.veiculo_latitude && item.veiculo_longitude) &&
												<a target='blank' href={`https://www.google.com/maps/place/${item.veiculo_latitude},${item.veiculo_longitude}`}>
													{item.veiculo_latitude}, {item.veiculo_longitude}
												</a>}
											</Td>
											<Td alignCenter small>{item.veiculo_status || ''}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

							<Grid cols="12 12 12 12">
								<div style={{ width: "100%", height: 500 }}>
									<MapLeaflet
										coordenadas={this.props.lista.filter(item => item.shipping_code_id == this.state.registroModal.shipping_code_id && item.veiculo_latitude && item.veiculo_longitude)
											.map(item => ({
												id: item.solicitacao_carga_id,
												titulo: item.cavalo,
												latitude: item.veiculo_latitude,
												longitude: item.veiculo_longitude,
												direcao: this.state.registroModal.rumo == 'RS' ? 'S' : 'N'
											}))}
										zoom={4}
										latitude={'-28.381875'}
										longitude={'-53.937355'}
										onChange={(coordenadas) => {
										}}
									/>
								</div>
							</Grid>

						</div>
						{/*<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, registroModal: null })}>Fechar</button>
						</div>*/}
					</div>
				</div>
			</div>
        );
    }

	modalOcorrencia() {

		const qtdSolicitacoes = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModalOcorrencia.shipping_code_id).length;
		let mostrarColunaSolicitacao = false;
		this.props.listaPrevisoes.filter(item => item.shipping_code_id == this.state.registroModalOcorrencia.shipping_code_id).map(item => {
			if ((item.solicitacoes || []).filter(sol => sol).length != qtdSolicitacoes) {
				mostrarColunaSolicitacao = true;
			}
		});

		let solicitacao = this.props.listaSolicitacoes.filter(item => item.shipping_code_id == this.state.registroModalOcorrencia.shipping_code_id)[0] || {};

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Shipping Code: {this.state.registroModalOcorrencia.shipping_code_id}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, registroModalOcorrencia: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll', marginBottom: 80 }}>

							<h6>Ocorrências</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Tipo</Th>
										<Th alignCenter>Responsável</Th>
										<Th alignCenter>Início</Th>
										<Th alignCenter>Fim</Th>
										<Th alignCenter>Descrição</Th>
										<Th alignCenter>Usuário</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaOcorrencias.filter(item => item.shipping_code_id == this.state.registroModalOcorrencia.shipping_code_id).map(item => (
										<Tr key={item.id_ocorrencia}>
											<Td alignCenter small>{item.descricao_tipo_ocorrencia || ''}</Td>
											<Td alignCenter small>{item.responsabilidade == 0 ? 'Cliente' : 'Letsara'}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.data_inicio)}</Td>
											<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.data_termino)}</Td>
											<Td alignCenter small>{item.comentario || ''}</Td>
											<Td alignCenter small>{item.usuario_inclusao || ''}</Td>
										</Tr>
									))}
								</TBody>
							</Table>

						</div>
					</div>
				</div>
			</div>
        );
    }

	modalContatos() {

        return (
			<div class='modal fade show' id='modal-md' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Contatos</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, contatosModal: null })}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll' }}>

							<h6>Placas</h6>

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Nome</Th>
										<Th alignCenter>Celular</Th>
										<Th alignCenter>WhatsApp</Th>
									</Tr>
								</THead>
								<TBody>
									{(this.props.usuarioCarregado.contatos || []).map(item => (
										<Tr key={item.id}>
											<Td alignCenter>{item.nome}</Td>
											<Td alignCenter>{FormatUtils.formatarTelefone(item.telefone)}</Td>
											<Td alignCenter>
												<a class='btn btn-success' target='blank' href={`https://api.whatsapp.com/send?phone=+55${item.telefone}&text=${encodeURI(`Olá ${item.nome}`)}`}>
													<i style={{ fontSize: 24 }} class='fab fa-whatsapp'></i>
												</a>
											</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.reportsTrack.modoTela,
	aguardando: state.reportsTrack.aguardando,
	lista: state.reportsTrack.lista.map(item => {
		if (state.auth.usuario && state.auth.usuario.id == 214) { //Demonstração
			return {
				...item,
				negociador: 'DEMONSTRAÇÃO',
				nome_remetente_sol: 'DEMONSTRAÇÃO',
				nome_destinatario_sol: 'DEMONSTRAÇÃO',
				veiculo_referencia: 'DEMONSTRAÇÃO',
				observacao: 'DEMONSTRAÇÃO'
			}
		} else {
			return item;
		}
	}),
	listaFronteiras: state.reportsTrack.listaFronteiras,
	listaNegociadores: state.reportsTrack.listaNegociadores,
	listaRumos: state.reportsTrack.listaRumos,
	listaFarolAtraso: state.reportsTrack.listaFarolAtraso,
	listaOcorrencias: state.reportsTrack.listaOcorrencias,
	listaSolicitacoes: state.reportsTrack.listaSolicitacoes,
	listaPrevisoes: state.reportsTrack.listaPrevisoes,
	listaEventos: state.reportsTrack.listaEventos,
	listaPrevisoesEventos: state.reportsTrack.listaPrevisoesEventos,
	listaOperacoes: state.reportsTrack.listaOperacoes,
	filtro: state.reportsTrack.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, getListaFronteiras, getListaNegociadores, getListaOperacoes, getLista, getListaOcorrencias,
	registrarAcesso
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReportsTrack);
