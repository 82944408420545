import './auth.css';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, signup, initForm, setModoTela, esqueciSenha, redefinirsenha } from './authActions';
import Row from '../common/layout/row';
import If from '../common/operator/if';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { ThemeProvider } from '@material-ui/styles';
import imagemLogoReports from '../assets/images/logo_reports_branco.png';
import imagemLogo from '../assets/images/logo_letsara_branca_horizontal.png';
import imagemLogin from '../assets/images/logo_vertical.png';
import imagemLogoInfinitum from '../assets/images/logo_infinitum_vertical_branca.png';

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {loginMode: true};
	}

	// changeMode() {
	// 	this.setState({ loginMode: !this.state.loginMode });
	// }

	onSubmit(values) {
		const { login, signup } = this.props;
		this.state.loginMode ? login(values) : signup(values);
	}

	getStyles() {
		return makeStyles((theme) => ({
			paper: {
				marginTop: theme.spacing(8),
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			},
			avatar: {
				margin: theme.spacing(1),
				backgroundColor: theme.palette.secondary.main
			},
			form: {
				width: '100%',
				marginTop: theme.spacing(1),
			},
			submit: {
				margin: theme.spacing(3, 0, 2),
			},
		}));
	}

	getTheme() {

		return createMuiTheme({
			palette: {
				primary: {
					// light: '#ffffff',
					main: '#ffffff',
					// dark: '#ffffff',
					// contrastText: será calculada para contrastar com palette.primary.main
				},
				secondary: {
					// light: '#0066ff',
					main: '#1f3269',
					// dark: será calculada com base palette.secondary.main,
					// contrastText: '#ffcc00',
				},
				// Usado por `getContrastText()` para maximizar o contraste entre
				// o plano de fundo e o texto.
				contrastThreshold: 3,
				// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
				// dois índices dentro de sua paleta tonal.
				// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
				tonalOffset: 0.2,
			},
		});
	}

	render() {

		const classes = this.getStyles();

		const { loginMode } = this.state;
		const { handleSubmit } = this.props;

		return (
			<ThemeProvider theme={this.getTheme()}>
				<div style={{ backgroundColor: '#1f3269', height: '100%' }}>
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundSize: 'cover' }}>
						<Container component="main" maxWidth="sm" style={{ width: '100%', marginTop: 80 }}>
							<CssBaseline />
							{/*<h1 style={{ color: '#fff', textAlign: 'center', paddingBottom: 20 }}>S I G</h1>*/}
							<div className={classes.paper} 
								style={{
									backgroundColor: '#4472c4', 
									paddingLeft: 16, 
									paddingTop: 40, 
									paddingRight: 16, 
									paddingBottom: 40,
									borderRadius: 40, 
									textAlign: 'center'
								}}>


								<img src={imagemLogo} style={{ width: 300, marginBottom: 20 }}/>
																
								<h1 style={{ fontSize: 32, color: '#ffffff', marginBottom: 20 }}>Portal Infinitum</h1>

								{this.props.modoTela == 'login' &&
								<div>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>

										<label htmlFor='login' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Usuário</label>
										<input
											className='form-control'
											placeholder={'Informe seu usuário'}
											type='text'
											value={this.props.formularioValues ? this.props.formularioValues.login : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													login: (e.target.value || '').toLowerCase()
												});
											})} />

										<label htmlFor='senha' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Senha</label>
										<input
											className='form-control'
											placeholder={'Informe sua senha'}
											type='password'
											value={this.props.formularioValues ? this.props.formularioValues.senha : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: (e.target.value || '').trim()
												});
											})} />

										<Button
											style={{ marginTop: 40 }}
											type="submit"
											fullWidth
											variant="contained"
											color="secondary"
											className={classes.submit} >
											Entrar
										</Button>
									</form>

									<div style={{ textAlign: 'center', marginTop: 20 }}>
										<a href='#' style={{ color: '#ffffff', marginLeft: 8, fontSize: 16, textDecoration: 'underline' }} onClick={() => this.props.setModoTela('esqueciSenha')}>Esqueci minha senha</a>
									</div>
								</div>}

								{this.props.modoTela == 'esqueciSenha' &&
								<div>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.props.esqueciSenha(v))}>

										<label htmlFor='login' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Usuário</label>
										<input
											className='form-control'
											placeholder={'Informe seu e-mail'}
											type='text'
											value={this.props.formularioValues ? this.props.formularioValues.login : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													login: (e.target.value || '').toLowerCase()
												});
											})} />

										<Button
											style={{ marginTop: 40 }}
											type="submit"
											fullWidth
											variant="contained"
											color="secondary"
											className={classes.submit} >
											Receber Código
										</Button>

										<div style={{ textAlign: 'center', marginTop: 20 }}>
											<a href='#' style={{ color: '#ffffff', marginLeft: 8, fontSize: 16, textDecoration: 'underline' }} onClick={() => this.props.setModoTela('login')}>Voltar para tela de login</a>
										</div>
									</form>
								</div>}

								{this.props.modoTela == 'redefinirSenha' &&
								<div>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.props.redefinirsenha(v))}>
																		
										<label htmlFor='login' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Usuário</label>
										<input
											className='form-control'
											placeholder={'Informe seu e-mail'}
											type='text'
											value={this.props.formularioValues ? this.props.formularioValues.login : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													login: (e.target.value || '').toLowerCase()
												});
											})} />
											
										<label htmlFor='senha' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Senha</label>
										<input
											className='form-control'
											placeholder={'Informe sua senha'}
											type='password'
											value={this.props.formularioValues ? this.props.formularioValues.senha : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: (e.target.value || '').trim()
												});
											})} />
											
										<label htmlFor='senha_confirmacao' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Confirme sua Senha</label>
										<input
											className='form-control'
											placeholder={'Informe sua senha'}
											type='password'
											value={this.props.formularioValues ? this.props.formularioValues.senha_confirmacao : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha_confirmacao: (e.target.value || '').trim()
												});
											})} />
											
										<label htmlFor='codigo' style={{ color: '#ffffff', textAlign: 'left', width: '100%', marginTop: 20 }}>Código</label>
										<input
											className='form-control'
											placeholder={'Informe o código de confirmação'}
											type='numeric'
											value={this.props.formularioValues ? this.props.formularioValues.codigo : ''}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													codigo: (e.target.value || '').trim()
												});
											})} />

										<Button
											style={{ marginTop: 40 }}
											type="submit"
											fullWidth
											variant="contained"
											color="secondary"
											className={classes.submit} >
											Redefinir Senha
										</Button>

										<div style={{ textAlign: 'center', marginTop: 20 }}>
											<a href='#' style={{ color: '#ffffff', marginLeft: 8, fontSize: 16, textDecoration: 'underline' }} onClick={() => this.props.setModoTela('login')}>Voltar para tela de login</a>
										</div>
									</form>
								</div>}
							</div>
							<Snackbar
								open={this.props.message.open}>
								<MuiAlert severity={this.props.message.type} variant="filled">
			    					{this.props.message.text}
			  					</MuiAlert>
						 	</Snackbar>
						</Container>

						<img src={imagemLogoInfinitum} style={{ width: 150, marginTop: 20 }}/>
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

Auth = reduxForm({form: 'authForm'})(Auth);
const mapStateToProps = state => ({
	message: state.auth.message,
	modoTela: state.auth.modoTela,
	formularioValues: getFormValues('authForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ login, signup, initForm, setModoTela, esqueciSenha, redefinirsenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
