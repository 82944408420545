const userKey = '_servicos_user';
const INITIAL_STATE = {
	listaMenu: []
}
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

        case 'MENU_PORTAL_LISTADO':
            return {
				...state,
				listaMenu: action.payload.data
			};

		default:
			return state;

	}
}
