import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import FormComprovante from './documentoViagemComprovanteForm';
import FormOcorrencia from '../ocorrencia/ocorrenciaForm';
import FormPalete from './viagemPaleteForm';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm, buscaInformacaoSolicitacao,
	setModoTelaComprovante, initFormComprovante, salvarComprovante, validarComprovanteFinanceiro , excluirComprovante, getListaComprovante, setListaComprovante,
	setModoTelaOcorrencia, getListaOcorrencia, getListaAberta, getListaComprovacao, getListaComprovada,
	setModoTelaPalete, initFormPalete, salvarPalete, finalizarPalete, reabrirPalete
} from './documentoViagemActions';

import {
	salvar as salvarOcorrencia, excluir as excluirOcorrencia, initForm as initFormOcorrencia, 
	getListaTipo as getListaTipoOcorrencia, getListaMotorista as getListaMotoristaOcorrencia,
	getListaGravidade as getListaGravidadeOcorrencia, getListaLocal as getListaLocalOcorrencia, 
	getListaResponsabilidade as getListaResponsabilidadeOcorrencia, getListaTipoCusto as getListaTipoCustoOcorrencia,
	getListaStatus as getListaStatusOcorrencia, getListaAcao as getListaAcaoOcorrencia, getListaUsuario as getListaUsuarioOcorrencia
} from '../ocorrencia/ocorrenciaActions';
import ColorUtils from '../common/colorUtils/ColorUtils';

class DocumentoViagemForm extends Component {

	state = {

	}

	componentDidMount() {
		this.props.getListaTipoOcorrencia();
		this.props.getListaMotoristaOcorrencia();
		this.props.getListaGravidadeOcorrencia();
		this.props.getListaLocalOcorrencia();
		this.props.getListaResponsabilidadeOcorrencia();
		this.props.getListaTipoCustoOcorrencia();
		this.props.getListaStatusOcorrencia();
		this.props.getListaAcaoOcorrencia();
		this.props.getListaUsuarioOcorrencia();
	}

	componentWillMount() {

		let initSeletorDocumento = () => {
			const fileSelectorDocumento = document.createElement('input');
			fileSelectorDocumento.setAttribute('type', 'file');
			fileSelectorDocumento.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.comprovante.arquivo || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.salvarComprovante({
							...this.props.formularioValues.comprovante,
							digital_validado: true,
							emails_operacao_enviar: this.props.formularioValues.emails_operacao || [],
							arquivo: lista
						});

						initSeletorDocumento();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorDocumento = fileSelectorDocumento;
		};

		initSeletorDocumento();

    }

    render() {

		let readOnly = this.props.excluir || this.props.visualizacao || (this.props.formularioValues && this.props.formularioValues.id_status && ((this.props.formularioValues.id_status == 2 && !this.props.comprovantes) || this.props.formularioValues.id_status == 3)) ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>

						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Cancelar'
											submit type={'danger'}
											icon={'fa fa-trash'}
											disabled={this.props.visualizacao} />
									) : null}{/*(!this.props.formularioValues || !this.props.formularioValues.id_status || this.props.formularioValues.id_status == 1 || (this.props.formularioValues.id_status == 2 && this.props.comprovantes) ? (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'}
											disabled={this.props.visualizacao} />
									) : null)*/}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.props.setModoTela('lista');
											this.props.getListaAberta();
											this.props.getListaComprovacao();
											this.props.getListaComprovada();
										}} />
								</Grid>
							</Row>
						</ContentCardFooter>

						<ContentCardBody>

							{!this.props.comprovantes ? (
								<>
									<Row>

										<Table responsive tableBordered>
											<THead>
												<Tr>
													<Th alignCenter width={80}>Alertas</Th>
													<Th alignCenter width={10}>Sol.</Th>
													<Th alignCenter width={10}>Rom.</Th>
													<Th alignCenter width={100}>Emissão</Th>
													<Th alignCenter width={100}>Descarga</Th>
													<Th alignCenter width={10}>Tipo Doc</Th>
													<Th alignCenter width={10}>Documento</Th>
													<Th alignCenter width={10}>MIC</Th>
													<Th alignCenter width={10}>Shipping</Th>
													<Th alignCenter width={10}>Veículo</Th>
													<Th alignCenter width={200}>Motorista Entrega</Th>
													<Th alignCenter width={10}>Tipo</Th>
													{this.props.formularioValues.id 
													&& (this.props.formularioValues.id_status == 1 || this.props.formularioValues.id_status == 2)
													&& this.props.sessao && (
														this.props.sessao.id == 1 //Infinitum
														|| this.props.sessao.id == 13 // Mauricio Junior Sfalcin 
														|| this.props.sessao.id == 30 // Clarissa Brauner
													) && 
													<Th alignCenter width={10}></Th>}
												</Tr>
											</THead>
											<TBody>
												<Tr>	
													<Td alignCenter minWidth={80}>
														{this.props.formularioValues.ocorrencia_aberta ? (
															<i class='fas fa-times-circle' style={{ color: '#ed0707', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
																title={`Existe ocorrência aberta`}>
															</i>
														) : (this.props.formularioValues.ocorrencia_finalizada ? (
															<i class='fas fa-times-circle' style={{ color: '#a1a1a1', fontSize: 16, paddingTop: 4, paddingLeft: 4 }} 
																title={`Existe ocorrência finalizada`}>
															</i>
														) : null)}
														{this.props.formularioValues.excecao &&
														<i class='fas fa-exclamation-circle' style={{ color: '#c49b04', fontSize: 16, paddingTop: 4, paddingLeft: 4 }}
															title={this.props.formularioValues.excecao ? `Exceção: ${this.props.formularioValues.excecao}` : ''}>
														</i>}
														{this.props.formularioValues.qtd_digital_selecionado > 0 &&
														<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
														{this.props.formularioValues.faturado &&
														<i class='fas fa-dollar-sign' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Documento faturado'} />}
													</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.solicitacao}</Td>
													<Td alignCenter minWidth={10}  small>{this.props.formularioValues.romaneio}</Td>
													<Td alignCenter minWidth={100} small>{this.props.formularioValues.datahora_viagem}</Td>
													<Td alignCenter minWidth={100} small>{this.props.formularioValues.datahora_descarga}</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.tipo_documento}</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.numero_documento}</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.mic_documento}</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.shipping}</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.cavalo}</Td>
													<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_motorista}</Td>
													<Td alignCenter minWidth={10} small>{this.props.formularioValues.tipo_veiculo}</Td>
													{this.props.formularioValues.id 
													&& (this.props.formularioValues.id_status == 1 || this.props.formularioValues.id_status == 2)
													&& this.props.sessao && (
														this.props.sessao.id == 1 //Infinitum
														|| this.props.sessao.id == 13 // Mauricio Junior Sfalcin 
														|| this.props.sessao.id == 30 // Clarissa Brauner
														|| this.props.sessao.id == 48 // Bruna Schneider Padilha
														|| this.props.sessao.id == 31 // Alana Costa
													) && 
													<Td alignCenter minWidth={10} small>
														<ButtonTable
															type={'warning'}
															icon={'fas fa-pencil-alt'}
															visible={true}
															title='Editar'
															small
															event={() => {
																this.props.setModoTela('cadastroManual', {
																	...this.props.formularioValues
																});
																this.props.initForm({
																	...this.props.formularioValues
																});
															}} />
													</Td>}
												</Tr>
											</TBody>
										</Table>
										<Table responsive tableBordered>
											<THead>
												<Tr>
													<Th alignCenter width={200}>Cliente</Th>
													<Th alignCenter width={200}>Recebedor</Th>
													<Th alignCenter width={200}>Local Coleta</Th>
													<Th alignCenter width={200}>Local Entrega</Th>
													<Th alignCenter width={200}>Operação</Th>
												</Tr>
											</THead>
											<TBody>
												<Tr>
													<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_cliente}</Td>
													<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.nome_recebedor}</Td>
													<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.cidade_coleta} - {this.props.formularioValues.estado_coleta} - {this.props.formularioValues.pais_coleta}</Td>
													<Td alignCenter minWidth={200} noWrap small>{this.props.formularioValues.cidade_entrega} - {this.props.formularioValues.estado_entrega} - {this.props.formularioValues.pais_entrega}</Td>
													<Td alignCenter minWidth={200} noWrap small backgroundColor={this.props.formularioValues.cor_perfil} color={ColorUtils.getCorFonte(this.props.formularioValues.cor_perfil)}>{`${this.props.formularioValues.nome_operacao }${this.props.formularioValues.nome_perfil ? ' - ' : ''}${this.props.formularioValues.nome_perfil || ''}`}</Td>
												</Tr>
											</TBody>
										</Table>
										{/*
										{this.props.formularioValues && this.props.formularioValues.id ? (
											<Select
												name='id_status'
												options={this.props.listaStatus}
												label='Status'
												cols='12 12 4 4'
												placeholder='Selecione o status'
												readOnly={'readOnly'}
												value={this.props.formularioValues.id_status}
												onChange={data => {
													if (data) {
														this.props.initForm({
															...this.props.formularioValues,
															id_status: data
														});
													}
												}} />
										) : null}

										<Field
											name='datahora_viagem'
											component={LabelAndInputMask}
											label='Data/Hora' placeholder='Informe a data e hora da viagem'
											cols='12 6 4 4'
											mask='99/99/9999 99:99'
											readOnly={readOnly} />

										{this.props.formularioValues && this.props.formularioValues.datahora_finalizacao ? (
											<Field
												name='datahora_finalizacao'
												component={LabelAndInputMask}
												label='Finalização' placeholder='Informe a data e hora da finalização'
												cols='12 6 4 4'
												mask='99/99/9999 99:99'
												readOnly={readOnly} />
										) : null}

										{this.props.formularioValues && this.props.formularioValues.datahora_comprovada ? (
											<Field
												name='datahora_comprovada'
												component={LabelAndInputMask}
												label='Comprovação' placeholder='Informe a data e hora da comprovação'
												cols='12 6 4 4'
												mask='99/99/9999 99:99'
												readOnly={readOnly} />
										) : null}

										<Select
											name='id_operacao'
											options={this.props.listaOperacao}
											label='Operação'
											cols='12 12 12 12'
											placeholder='Selecione a operação'
											readOnly={readOnly ? readOnly : (this.props.formularioValues.id ? 'readOnly' : null)}
											value={this.props.formularioValues.id_operacao}
											onChange={data => {
												if (data) {
													this.props.initForm({
														...this.props.formularioValues,
														id_operacao: data
													});
												}
											}} />
										
										<Field
											name='shipping'
											component={LabelAndInputNumber}
											label='Shipping Code' placeholder='Informe o shipping code'
											cols='12 6 3 3'
											readOnly={'readOnly'} />

										<Field
											name='solicitacao'
											component={LabelAndInput}
											label='Solicitação' placeholder='Informe a solicitacao'
											cols='12 6 3 2'
											readOnly={'readOnly'} />
										
										
										<Grid cols='6 6 4 3' style={{ marginTop: 29 }}>
											<Button
												text='Buscar Informações KMM'
												type={'primary'}
												icon={'fa fa-check'}
												disabled={this.props.visualizacao}
												event={() => {
													this.props.buscaInformacaoSolicitacao(this.props.formularioValues);
												}} />
										</Grid>
										
								
										<Field
											name='tipo_documento'
											component={LabelAndInput}
											label='Tipo' placeholder='Informe o tipo'
											cols='12 3 1 1'
											readOnly={'readOnly'} />

										<Field
											name='numero_documento'
											component={LabelAndInput}
											label='Número' placeholder='Informe o número'
											cols='12 4 3 3'
											readOnly={'readOnly'} />

										<Field
											name='mic_documento'
											component={LabelAndInput}
											label='MIC' placeholder='Informe o MIC'
											cols='12 5 3 3'
											readOnly={'readOnly'} />
										*/}
									</Row>
									
									{/*
									<Row>
										<Field
											name='datahora_carga'
											component={LabelAndInputMask}
											label='Data/Hora Carga' placeholder='Informe a data e hora da carga'
											cols='12 6 4 4'
											mask='99/99/9999 99:99'
											readOnly={readOnly} />

										<Field
											name='datahora_descarga'
											component={LabelAndInputMask}
											label='Data/Hora Descarga' placeholder='Informe a data e hora da descarga'
											cols='12 6 4 4'
											mask='99/99/9999 99:99'
											readOnly={readOnly} />
									</Row>

									<Row>
										<Field
											name='cavalo'
											component={LabelAndInput}
											label='Cavalo' placeholder='Informe a placa'
											cols='12 6 2 2'
											readOnly={readOnly} />

										<Field
											name='carreta1'
											component={LabelAndInput}
											label='Carreta 1' placeholder='Informe a placa'
											cols='12 6 2 2'
											readOnly={readOnly} />

										<Field
											name='carreta2'
											component={LabelAndInput}
											label='Carreta 2' placeholder='Informe a placa'
											cols='12 6 2 2'
											readOnly={readOnly} />

										<Field
											name='nome_motorista'
											component={LabelAndInput}
											label='Motorista' placeholder='Informe o motorista'
											cols='12 6 6 6'
											readOnly={readOnly} />
									</Row>

									<Row>
										<Field
											name='id_cliente'
											component={SelectDefault}
											options={this.props.listaCliente}
											label='Cliente'
											cols='12 6 4 4'
											placeholder='Selecione o cliente'
											readOnly={readOnly} />

										<Field
											name='cidade_coleta'
											component={LabelAndInput}
											label='Cidade da Coleta' placeholder='Informe a cidade'
											cols='12 6 4 4'
											readOnly={readOnly} />

										<Field
											name='estado_coleta'
											component={LabelAndInput}
											label='Estado da Coleta' placeholder='Informe o estado'
											cols='12 6 2 2'
											readOnly={readOnly} />

										<Field
											name='pais_coleta'
											component={LabelAndInput}
											label='País da Coleta' placeholder='Informe o país'
											cols='12 6 2 2'
											readOnly={readOnly} />
									</Row>

									<Row>
										<Field
											name='id_recebedor'
											component={SelectDefault}
											options={this.props.listaCliente}
											label='Recebedor'
											cols='12 6 4 4'
											placeholder='Selecione o recebedor'
											readOnly={readOnly} />

										<Field
											name='cidade_entrega'
											component={LabelAndInput}
											label='Cidade da Entrega' placeholder='Informe a cidade'
											cols='12 6 4 4'
											readOnly={readOnly} />

										<Field
											name='estado_entrega'
											component={LabelAndInput}
											label='Estado da Entrega' placeholder='Informe o estado'
											cols='12 6 2 2'
											readOnly={readOnly} />

										<Field
											name='pais_entrega'
											component={LabelAndInput}
											label='País da Entrega' placeholder='Informe o país'
											cols='12 6 2 2'
											readOnly={readOnly} />
									</Row>
									*/}
								</>
							) : null}
						</ContentCardBody>

					</Form>
				</ContentCard>
				{this.props.formularioValues.id && !this.props.comprovantes ?
					this.renderItens(readOnly)
				: null}

				{this.props.formularioValues.id ?
					this.renderOcorrencias(readOnly)
				: null}
				
				{this.props.formularioValues.id ?
					this.renderPaletes(readOnly)
				: null}

				{this.props.formularioValues.id ?
					this.renderHistorico(readOnly)
				: null}

			</div>
        )
    }

	renderItens(readOnly) {

		if (this.props.modoTelaComprovante == 'cadastro') {
			return (
				<FormComprovante onSubmit={this.props.salvarComprovante} />
			);
		} else if (this.props.modoTelaComprovante == 'exclusao') {
			return (
				<FormComprovante excluir onSubmit={this.props.excluirComprovante} />
			);
		} else if (this.props.modoTelaComprovante == 'lista') {
			return (

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								{/*this.props.formularioValues && this.props.formularioValues.id_status == 1 ? (
									<Button
										text='Adicionar Documento'
										type={'success'}
										icon={'fa fa-plus'}
										disabled={false}
										event={() => {
											this.props.setModoTelaComprovante('cadastro', {
												id_documento_viagem: this.props.formularioValues.id,
											});
											this.props.initFormComprovante({
												id_documento_viagem: this.props.formularioValues.id
											});
										}} />
									) : null*/}
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Documento</Th>
									<Th alignCenter>Última Interação</Th>
									<Th alignCenter>Cliente</Th>
									<Th alignCenter>Letsara</Th>
									<Th alignCenter>Digital</Th>
									<Th alignCenter>Documento Digital</Th>
									{this.props.modoFinanceiro ? (
										<Th alignCenter>Validado</Th>
									) : null}
									<Th alignCenter>Documento Original</Th>
									{this.props.modoFinanceiro ? (
										<Th alignCenter>Validado</Th>
									) : null}
								</Tr>
							</THead>
							<TBody>
								{this.props.listaComprovante.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td small>{item.nome_documento}</Td>
										<Td small alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
										<Td small alignCenter width={100}>{item.nome_cliente_tipo || '-'}</Td>
										<Td small alignCenter width={100}>{item.nome_letsara_tipo || '-'}</Td>
										<Td small alignCenter width={100}>{item.digital ? 'OBRIGATÓRIO' : '-'}</Td>
										<Td small alignCenter width={300}>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
												{(item.arquivo || []).map(arquivo => (
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														{/*<span class="btn btn-outline-primary fileinput-button dz-clickable">
															<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 16 }} />
														</span>*/}
														<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
															<button
																type='button'
																class='btn btn-flat btn-outline-primary'
																style={{ width: this.props.modoFinanceiro ? '100%' : '48%' }}
																disabled={arquivo.arquivo_base64 ? true : false}
																onClick={() => {
																	window.open(`https://api-letsarapremiacao.infinitum-ti.com/${arquivo.pdf}`, '_blank');
																}}>
																<i class='fas fa-download'></i>
															</button>
															<button
																type='button'
																class='btn btn-flat btn-outline-danger'
																style={{ width: '48%' }}
																disabled={this.props.visualizacao || this.props.modoFinanceiro || this.props.formularioValues.id_status == 4 /* FINALIZADA */}
																onClick={() => {
																	this.props.salvarComprovante({
																		...item,
																		arquivo: item.arquivo.filter(item => item != arquivo)
																	});
																}}>
																<i class='fas fa-trash-alt'></i>
															</button>
														</div>
													</div>
												))}
												{!this.props.visualizacao && !this.props.modoFinanceiro && this.props.formularioValues.id_status != 4 /* FINALIZADA */ ? (
													<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => {
														this.fileSelectorDocumento.click();
														this.props.initForm({
															...this.props.formularioValues,
															comprovante: item
														});
													}}>
														<img src={pdfAdd} style={{ height: 16 }} />
													</span>
												) : null}
											</div>
										</Td>
										{this.props.modoFinanceiro ? (
											<Td alignCenter width={100}>
												<button
													type='button'
													class={`btn btn-flat btn-outline-${item.digital_validado ? 'success' : 'danger'}`}
													style={{  }}
													disabled={this.props.visualizacao}
													onClick={() => {
														this.props.validarComprovanteFinanceiro({
															...item,
															digital_validado: !item.digital_validado
														});
													}}>
													<i class={`fas fa-${item.digital_validado ? 'check' : 'square'}`} style={{ fontSize: 16, color: item.digital_validado ? null : '#fff' }}></i>
												</button>
											</Td>
										) : null}
										<Td alignCenter width={100}>
											<button
												type='button'
												class={`btn btn-flat btn-outline-${item.original ? 'success' : 'danger'}`}
												style={{  }}
												disabled={this.props.visualizacao || this.props.modoFinanceiro || this.props.formularioValues.id_status == 4 /* FINALIZADA */}
												onClick={() => {
													this.props.salvarComprovante({
														...item,
														original: !item.original
													});
												}}>
												<i class={`fas fa-${item.original ? 'check' : 'square'}`} style={{ fontSize: 16, color: item.original ? null : '#fff' }}></i>
											</button>
										</Td>
										{this.props.modoFinanceiro ? (
											<Td alignCenter width={100}>
												<button
													type='button'
													class={`btn btn-flat btn-outline-${item.original_validado ? 'success' : 'danger'}`}
													style={{  }}
													disabled={this.props.visualizacao}
													onClick={() => {
														this.props.validarComprovanteFinanceiro({
															...item,
															original_validado: !item.original_validado
														});
													}}>
													<i class={`fas fa-${item.original_validado ? 'check' : 'square'}`} style={{ fontSize: 16, color: item.original_validado ? null : '#fff' }}></i>
												</button>
											</Td>
										) : null}
									</Tr>
								))}
							</TBody>
						</Table>

						{this.props.formularioValues.excecao &&
						<Table responsive>
							<THead>
								<Tr>
									<Th>Exceção da Operação</Th>
								</Tr>
							</THead>
							<TBody>
								<Tr backgroundColor={'#ff9f00'}>
									<Td>{this.props.formularioValues.excecao}</Td>
								</Tr>
							</TBody>
						</Table>}
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	renderOcorrencias(readOnly) {

		if (this.props.modoTelaOcorrencia == 'cadastro') {
			return (
				<FormOcorrencia voltarComprovante={() => this.props.setModoTelaOcorrencia('lista')} onSubmit={(registro) => this.props.salvarOcorrencia(registro, () => {
					this.props.setModoTelaOcorrencia('lista');
					this.props.getListaOcorrencia(this.props.formularioValues.id);
				})} />
			);
		} else if (this.props.modoTelaOcorrencia == 'exclusao') {
			return (
				<FormOcorrencia voltarComprovante={() => this.props.setModoTelaOcorrencia('lista')} excluir onSubmit={(registro) => this.props.excluirOcorrencia(registro, () => {
					this.props.setModoTelaOcorrencia('lista');
					this.props.getListaOcorrencia(this.props.formularioValues.id);
				})} />
			);
		} else if (this.props.modoTelaOcorrencia == 'lista') {
			return (

				<ContentCard>
					<ContentCardHeader>
						<label>Ocorrências</label>
						<Row>
							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar Ocorrência'
									type={'success'}
									icon={'fa fa-plus'}
									disabled={false}
									event={() => {
										this.props.setModoTelaOcorrencia('cadastro', {
											id_documento_viagem: this.props.formularioValues.id,
											descricao_documento: this.props.formularioValues.numero_documento ? `${this.props.formularioValues.tipo_documento}: ${this.props.formularioValues.numero_documento}` : '',
											datahora_ocorrencia: this.props.formularioValues.datahora_descarga ? this.props.formularioValues.datahora_descarga : DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
										this.props.initFormOcorrencia({
											id_documento_viagem: this.props.formularioValues.id,
											descricao_documento: this.props.formularioValues.numero_documento ? `${this.props.formularioValues.tipo_documento}: ${this.props.formularioValues.numero_documento}` : '',
											datahora_ocorrencia: this.props.formularioValues.datahora_descarga ? this.props.formularioValues.datahora_descarga : DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
										});
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter>Número</Th>
									<Th alignCenter>Situação</Th>
									<Th alignCenter>Status</Th>
									<Th alignCenter>Tipo</Th>
									<Th alignCenter>Data/Hora</Th>
									<Th alignCenter>Ação Requerida</Th>
									<Th alignCenter>Motorista Vinculado</Th>
									<Th alignCenter>Observação</Th>
									<Th width={5} alignCenter><i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} /></Th>
									<Th alignCenter width={100}></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaOcorrencia.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td small alignCenter small>{item.id}</Td>
										<Td small alignCenter small>{item.datahora_finalizacao ? 'Finalidada' : 'Em Aberto'}</Td>
										<Td small alignCenter small>{item.nome_status}</Td>
										<Td small alignCenter small>{item.nome_tipo}</Td>
										<Td small alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia)}</Td>
										<Td small alignCenter small>{item.nome_acao}</Td>
										<Td small alignCenter small>{item.nome_motorista}</Td>
										<Td small alignCenter small>{item.observacao}</Td>
										<Td alignCenter minWidth={5} small noWrap>
											{(item.arquivo || []).length > 0 &&
											<i class='fas fa-paperclip' style={{ marginLeft: 4, paddingTop: 4, fontSize: 16 }} title={'Digital anexado'} />}
										</Td>
										<Td alignCenter width={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												disabled={readOnly}
												title='Editar'
												small
												event={() => {
													this.props.setModoTelaOcorrencia('cadastro', {
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
													this.props.initFormOcorrencia({
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												disabled={item.datahora_finalizacao}
												title='Excluir'
												small
												event={() => {
													this.props.setModoTelaOcorrencia('exclusao', {
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
													this.props.initFormOcorrencia({
														...item,
														percentual_desconto: FormatUtils.formatarValorTela(item.percentual_desconto, 2),
														datahora_ocorrencia: DateFormat.formatarDataHoraSqlParaTela(item.datahora_ocorrencia),
														datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
														datahora_pagamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_pagamento),
														custo: FormatUtils.formatarValorTela(item.custo, 2),
														valor: FormatUtils.formatarValorTela(item.valor, 2),
														valor_autorizado: FormatUtils.formatarValorTela(item.valor_autorizado, 2)
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	renderPaletes(readOnly) {
		
		return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<label>Paletes</label>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th alignCenter>NF Palete/Produto</Th>
									<Th>Propriedade</Th>
									<Th>Produto</Th>
									<Th alignRight>Quantidade</Th>
									<Th alignCenter>Cavalo</Th>
									<Th alignCenter>Carreta</Th>
									<Th>Motorista</Th>
									<Th alignCenter>Redespacho</Th>
									<Th alignCenter>Status</Th>
									<Th>Evento</Th>
									<Th width={100}></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaPalete.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)}</Td>
										<Td small alignCenter>{item.nota_fiscal_paletes || ''}{item.nota_fiscal_paletes && item.nota_fiscal_produtos ? ' / ' : ''}{item.nota_fiscal_produtos || ''}</Td>
										<Td small>{item.nome_propriedade}</Td>
										<Td small>{item.nome_produto || ''}</Td>
										<Td small alignRight>{item.quantidade}</Td>
										<Td small alignCenter>{item.cavalo || ''}</Td>
										<Td small alignCenter>{item.carreta || ''}</Td>
										<Td small>{item.motorista || ''}</Td>
										<Td small alignCenter>{item.redespacho ? 'Sim' : 'Não'}</Td>
										<Td small alignCenter>{item.datahora_finalizacao ? 'Finalizada' : 'Aberta'}</Td>
										<Td small>{item.ultimo_evento}</Td>
										<Td width={100}>
											{this.props.modoTelaPalete == 'lista' && !item.datahora_finalizacao ?
												<ButtonTable
													type={'warning'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													disabled={false}
													title='Editar'
													small
													event={() => {
														this.props.setModoTelaPalete('cadastro', {
															...item
														});
														this.props.initFormPalete({
															...item
														});
													}} />
											: null}
											
											{this.props.modoTelaPalete == 'lista' ?
												<ButtonTable
													type={'success'}
													icon={`fas fa-${!item.datahora_finalizacao ? 'check' : 'undo'}`}
													visible={true}
													disabled={false}
													title={item.datahora_finalizacao ? 'Reabrir' : 'Finalizar'}
													small
													event={() => {
														item.datahora_finalizacao ? this.props.reabrirPalete(item) : this.props.finalizarPalete(item);
													}} />
											: null}
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
				{this.props.modoTelaPalete == 'cadastro' ?
					<FormPalete onSubmit={this.props.salvarPalete} />
				: null}
			</>
		);
	}

	renderHistorico(readOnly) {
		
		return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<label>Histórico</label>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th alignCenter>Operação</Th>
									<Th alignCenter>Registro</Th>
									<Th alignCenter>Anterior</Th>
									<Th alignCenter>Atual</Th>
									<Th alignCenter>Descrição</Th>
									<Th alignCenter>Usuário</Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaHistorico.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_historico)}</Td>
										<Td small alignCenter>{item.tipo}</Td>
										<Td small alignCenter>{item.nome}</Td>
										<Td small alignCenter>{item.anterior || ''}</Td>
										<Td small alignCenter>{item.atual || ''}</Td>
										<Td small alignCenter>{item.descricao || ''}</Td>
										<Td small alignCenter>{item.nome_usuario || ''}</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</>
		);
	}

}

DocumentoViagemForm = reduxForm({form: 'documentoViagemForm', destroyOnUnmount: false})(DocumentoViagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('documentoViagemForm')(state),
	registro: state.documentoViagem.registro,
	listaComprovante: state.documentoViagem.listaComprovante,
	listaOcorrencia: state.documentoViagem.listaOcorrencia,
	listaOperacao: state.documentoViagem.listaOperacao,
	listaDocumento: state.documentoViagem.listaDocumento,
	listaStatus: state.documentoViagem.listaStatus,
	listaCliente: state.documentoViagem.listaCliente,
	listaPalete: state.documentoViagem.listaPalete,
	listaHistorico: state.documentoViagem.listaHistorico,
	modoTelaComprovante: state.documentoViagem.modoTelaComprovante,
	modoTelaOcorrencia: state.documentoViagem.modoTelaOcorrencia,
	modoTelaPalete: state.documentoViagem.modoTelaPalete
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, buscaInformacaoSolicitacao, getListaAberta, getListaComprovacao, getListaComprovada,
	setModoTelaComprovante, initFormComprovante, salvarComprovante, validarComprovanteFinanceiro, excluirComprovante, getListaComprovante, setListaComprovante,
	setModoTelaOcorrencia, initFormOcorrencia, salvarOcorrencia, excluirOcorrencia, getListaOcorrencia,
	getListaTipoOcorrencia, getListaMotoristaOcorrencia,
	getListaGravidadeOcorrencia, getListaLocalOcorrencia, 
	getListaResponsabilidadeOcorrencia, getListaTipoCustoOcorrencia,
	getListaStatusOcorrencia, getListaAcaoOcorrencia, getListaUsuarioOcorrencia,
	setModoTelaPalete, initFormPalete, salvarPalete, finalizarPalete, reabrirPalete
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoViagemForm);
