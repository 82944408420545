import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import axios from 'axios';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';

export function getListaMenu() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/usuarioMenu?id_usuario=${getState().auth.usuario ? getState().auth.usuario.id : 0}`)
		.then(resp => {
			dispatch({
		        type: 'MENU_PORTAL_LISTADO',
		        payload: resp
		    })
		})
		.catch(e => {
			console.log(e);
		})
	}
}
