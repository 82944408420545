const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaPerfil: [],
	listaCategoria: [],
	listaClasse: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'OCORRENCIA_TIPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'OCORRENCIA_TIPO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'OCORRENCIA_TIPO_PERFIL_SELECT_LISTADO':
			return {
				...state,
				listaPerfil: action.payload.data
			};

		case 'OCORRENCIA_TIPO_CATEGORIA_SELECT_LISTADO':
			return {
				...state,
				listaCategoria: action.payload.data
			};

		case 'OCORRENCIA_TIPO_CLASSE_SELECT_LISTADO':
			return {
				...state,
				listaClasse: action.payload.data
			};

        default:
            return state;
    }
}
