import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './laudoOcorrenciaForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaTipo } from './laudoOcorrenciaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import consts from '../consts';

class LaudoOcorrencia extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTipo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200}alignCenter small>Ocorrência</Th>
								<Th width={200}alignCenter small>Observação</Th>
								<Th width={60}alignCenter small>Status</Th>
								<Th width={120}alignCenter small>Abertura</Th>
								<Th width={120}alignCenter small>Fechamento</Th>
								<Th width={120}alignCenter small>Usuário</Th>
								<Th width={120}alignCenter small>Alteração</Th>
								<Th alignRight width={60}>
									<ButtonTable
										type={'success'}
										icon={'fas fa-plus'}
										visible={true}
										event={() => {
											this.props.setModoTela('cadastro', { id_controle: this.props.id_controle });
											this.props.initForm({
												id_controle: this.props.id_controle,
												datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
											});
										}} />
								</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista.filter(item => {
								return item.id_controle == this.props.id_controle;
							})).map((item, i) => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`} >
									<Td alignCenter small>{item.nome_tipo}</Td>
									<Td alignCenter small>{item.observacao}</Td>
									<Td alignCenter small
										backgroundColor={!item.datahora_fechamento ? '#de0025' : null} 
										color={!item.datahora_fechamento ? '#ffffff' : null} 
										fontWeight='bold'>{item.datahora_fechamento ? 'Fechada' : 'Aberta'}</Td>
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura)}</Td>
									<Td alignCenter small>
										{item.datahora_fechamento ? (
											DateFormat.formatarDataHoraSqlParaTela(item.datahora_fechamento)
										) : (
											<ButtonTable
												type={'success'}
												icon={'fas fa-check'}
												visible={true}
												small
												event={() => {
													this.props.salvar({
														...item,
														datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura),
														datahora_fechamento: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
													});
												}} />
										)}
									</Td>
									<Td alignCenter small>{item.nome_usuario_alteracao}</Td>
									<Td alignCenter small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={true}
											small
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura),
													datahora_fechamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fechamento)
												});
												this.props.initForm({
													...item,
													datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura),
													datahora_fechamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fechamento)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={true}
											small
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura),
													datahora_fechamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fechamento)
												});
												this.props.initForm({
													...item,
													datahora_abertura: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abertura),
													datahora_fechamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_fechamento)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.laudoOcorrencia.modoTela,
	lista: state.laudoOcorrencia.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LaudoOcorrencia);
