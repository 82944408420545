import './menuPortal.css';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Row from '../common/layout/row';
import If from '../common/operator/if';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { ThemeProvider } from '@material-ui/styles';
import imagemLogoReports from '../assets/images/logo_reports_branco.png';
import imagemLogo from '../assets/images/logo_letsara_branca_horizontal.png';
import imagemLogin from '../assets/images/logo_vertical.png';
import imagemLogoInfinitum from '../assets/images/logo_infinitum_vertical_branca.png';

import { getListaMenu } from './menuPortalActions';

class MenuPortal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}

	componentWillMount() {
		this.props.getListaMenu();
	}

	getStyles() {
		return makeStyles((theme) => ({
			paper: {
				marginTop: theme.spacing(8),
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			},
			avatar: {
				margin: theme.spacing(1),
				backgroundColor: theme.palette.secondary.main
			},
			form: {
				width: '100%',
				marginTop: theme.spacing(1),
			},
			submit: {
				margin: theme.spacing(3, 0, 2),
			},
		}));
	}

	getTheme() {

		return createMuiTheme({
			palette: {
				primary: {
					// light: será calculada com base em palette.primary.main,
					main: '#1f3269',
					// dark: será calculada com base em palette.primary.main,
					// contrastText: será calculada para contrastar com palette.primary.main
				},
				secondary: {
					light: '#0066ff',
					main: '#0044ff',
					// dark: será calculada com base palette.secondary.main,
					contrastText: '#ffcc00',
				},
				// Usado por `getContrastText()` para maximizar o contraste entre
				// o plano de fundo e o texto.
				contrastThreshold: 3,
				// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
				// dois índices dentro de sua paleta tonal.
				// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
				tonalOffset: 0.2,
			},
		});
	}

	render() {

		const classes = this.getStyles();

		let administrador = this.props.usuarioCarregado && this.props.usuarioCarregado.id_perfil == 1; //Administrador

		let escala = 1;
		if (window.innerWidth >= 1810) {
			escala = 1;
		} else if (window.innerWidth >= 1700) {
			escala = 0.9;
		} else if (window.innerWidth >= 1500) {
			escala = 0.82;
		} else if (window.innerWidth >= 1000) {
			escala = 0.7;
		} else if (window.innerWidth >= 661) {
			escala = 0.6;
		} else if (window.innerWidth >= 567) {
			escala = 0.52;
		} else if (window.innerWidth >= 378) {
			escala = 0.5;
		} else if (window.innerWidth >= 350) {
			escala = 0.48;
		} else {
			escala = 0.46;
		}

		return (
			<ThemeProvider theme={this.getTheme()}>
				<CssBaseline />
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#1f3269', minHeight: window.innerHeight, padding: 40 * escala }}>					
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', marginBottom: 20 }}>
						<h1 style={{ marginBottom: 0, color: '#ffffff', fontSize: 24 * escala, maxWidth: window.innerWidth - (320 * escala) }}>Olá {this.props.sessao.nome}, seja bem-vindo(a) ao Portal Infinitum</h1>

						<img src={imagemLogo} style={{ width: 230 * escala }}/>
					</div>
					
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
						{this.props.listaMenu.map(menu => {

							return (
								<a key={menu.id} class={menu.acessa ? 'menu-portal-item' : null}
									href={menu.acessa && !menu.rota_menu ? menu.link_menu : null}
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										width: 200 * escala,
										height: 200 * escala,
										margin: 8 * escala,
										padding: 8 * escala,
										backgroundColor: '#4472c4',
										borderRadius: 20 * escala, 
										textAlign: 'center',
										opacity: menu.acessa ? 1 : 0.4,
										cursor: menu.acessa ? 'pointer' : 'not-allowed'
									}}
									onClick={() => {
										if (menu.acessa && menu.rota_menu) {
											window.location = `/#${menu.rota_menu}`;
											window.location.reload();
										}
									}}>
									<i style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 48 * escala, color: '#ffffff', height: 80 }} class={menu.icone_menu}></i>

									<h1 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 18 * escala, color: '#ffffff', height: 80 }}>{menu.nome_menu}</h1>
								</a>
							);
						})}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<img src={imagemLogoInfinitum} style={{ width: 150 * escala, marginTop: 20 * escala }}/>
						
						{administrador &&
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								width: 100 * escala,
								height: 100 * escala,
								marginTop: 30,
								textAlign: 'center',
								opacity: 1,
								cursor: 'pointer'
							}}
							onClick={() => {
								window.location = `/#/usuario`;
								window.location.reload();
							}}>
							<i style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 32 * escala, color: '#ffffff', height: 40 * escala }} class={'fas fa-cog'}></i>

							<h1 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 16 * escala, color: '#ffffff', height: 30 * escala }}>Configuração</h1>
						</div>}
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	listaMenu: state.menuPortal.listaMenu
});
const mapDispatchToProps = dispatch => bindActionCreators({ getListaMenu }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MenuPortal);
